import React from 'react';

import Form from './Form.js';
import FollowBlockContext from './FollowBlockContext.js';
import { VerifyEmailAndHandleSignIn } from './VerifyEmail.js';
import { trackSignInAttempt, trackSignInFailure } from './CFAnalytics.js';

class StartCreation extends React.Component {
    render() {
        return (
            <div className="info-section">
                <div className="info-title">
                    Create account
                </div>
                <Form submitText="Send verification email" onSubmit={ this.props.onSubmit } message={ this.props.message }
                    fields={ [
                        {
                            label: 'Email address',
                            id: 'email',
                            type: 'text'
                        },
                        {
                            label: 'Password (leave blank to only allow sign in via confirmation email)',
                            id: 'password',
                            type: 'password'
                        },
                        {
                            label: 'Confirm password',
                            id: 'confirmPassword',
                            type: 'password'
                        }
                    ] }/>
            </div>
        );
    }
}

class CreateAccountVerifyEmail extends VerifyEmailAndHandleSignIn {
    messageAction = 'verify your email address and create your account'
    workingMessage = 'Creating account...'
    isSignIn = false

    async submitCode(enteredCode) {
        return await this.props.ui.zn.attemptEmailConfirmationAndAccountCreation(enteredCode, this.props.email, this.props.password);
    }
}

class CreateAccount extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            display: 'start',
            message: '',
            email: null,
            password: null
        };

        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
    }

    async handleEmailSubmit(formData) {
        trackSignInAttempt(false, true);

        if(formData.password !== formData.confirmPassword) {
            this.setState({
                message: 'Passwords do not match.'
            });
            trackSignInFailure(false, 'password confirmation mismatch');
        } else {
            const passwordEntry = formData.password;
            const password = passwordEntry.length > 0 ? passwordEntry : null;
            this.setState({
                message: 'Sending email...'
            });
            
            const confirmationRequest = await this.props.ui.zn.requestSignUpConfirmationEmail(formData.email);

            if(confirmationRequest.wasSuccessful) {
                this.setState({
                    display: 'verifyEmail',
                    message: '',
                    email: formData.email,
                    password: password
                });
            } else {
                const confirmationErrorMessage = confirmationRequest.message;
                
                this.setState({
                    message: confirmationErrorMessage
                });
            }
        }
    }

    render() {
        if(this.state.display === 'start') {
            return <StartCreation message={ this.state.message } onSubmit={ this.handleEmailSubmit }/>;
        } else if(this.state.display === 'verifyEmail') {
            return <CreateAccountVerifyEmail ui={ this.props.ui } email={ this.state.email } password={ this.state.password }/>;
        }
    }
}

CreateAccount.contextType = FollowBlockContext;

export default CreateAccount;