import { ZNDataManager } from "./DataManager";
import { FREE_USER, GROUPED_FEED_MODE, PLAN_ID_TO_PLAN } from "./general";

export class LocalStorageDataManager extends ZNDataManager {
    async read() {
        const storedData = localStorage.znData;
        const dataStr = storedData ?? process.env.REACT_APP_DEFAULT_ZN_DATA;
        this.usedDefaults = !storedData;
        this.savedData = JSON.parse(dataStr);

        this.loadStoredConcepts();

        this.userId = this.getUserIdFromLastLoadedData();
        this.userEmailAddress = this.getUserEmailAddressFromLastLoadedData();
        this.userPlan = this.getUserPlanFromLastLoadedData();
        this.userFeedMode = this.getUserFeedModeFromLastLoadedData();
        this.userFollowQueries = this.getUserFollowQueriesFromLastLoadedData();
        this.userHideQueries = this.getUserHideQueriesFromLastLoadedData();
    }

    async handleDataUpdate() {
        await this.write();
    }

    getUserIdFromLastLoadedData() {
        return this.savedData.accountData?.email;
    }

    getUserEmailAddressFromLastLoadedData() {
        const accountData = this.savedData.accountData;

        if(accountData && accountData.email) {
            return accountData.email;
        } else {
            return null;
        }
    }

    getUserPlanFromLastLoadedData() {
        return PLAN_ID_TO_PLAN[this.savedData.accountType] ?? FREE_USER;
    }

    getUserFeedModeFromLastLoadedData() {
        return this.savedData.feedMode ?? GROUPED_FEED_MODE;
    }

    getUserQueriesDataFromStorageKeyUsingLastLoadedData(storageKey) {
        return this.savedData[storageKey] ?? [];
    }

    getUserQueriesSetFromStorageKeyAndLastLoadedData(storageKey) {
        const queriesData = this.getUserQueriesDataFromStorageKeyUsingLastLoadedData(storageKey);
        return this.zn.processQueriesDataAndGetQueriesSet(queriesData);
    }

    getUserFollowQueriesFromLastLoadedData() {
        return this.getUserQueriesSetFromStorageKeyAndLastLoadedData('followQueryData');
    }

    getUserHideQueriesFromLastLoadedData() {
        return this.getUserQueriesSetFromStorageKeyAndLastLoadedData('blockQueryData');
    }

    getStoredConceptsIndexes() {
        return this.savedData.conceptsIndexes ?? {};
    }

    getStoredConceptsLabels() {
        return this.savedData.concepts ?? {};
    }

    loadStoredConcepts() {
        this.zn.processConceptData(this.getStoredConceptsLabels(), this.getStoredConceptsIndexes());
    }
    
    async write() {
        // Account data
        let accountData;

        if(this.userEmailAddress) {
            accountData = {
                userId: this.userId,
                email: this.userEmailAddress
            };
        } else {
            accountData = null;
        }
        
        // Save data for concepts and sources that are referenced by queries.
        const storeConcepts = new Set();

        for(const query of [...this.userFollowQueries, ...this.userHideQueries]) {
            for(const topic of query.topics) {
                storeConcepts.add(topic);
            }

            const source = query.source;

            if(source) {
                storeConcepts.add(source);
            }

            for(const author of query.authors) {
                storeConcepts.add(author);
            }
        }

        const storeConceptData = {};

        for(const concept of Array.from(storeConcepts)) {
            storeConceptData[concept.id] = concept.label;
        }

        // Save queries.
        const followQueryData = [];
        
        for(const query of [...this.userFollowQueries]) {
            followQueryData.push(query.toData());
        }

        const blockQueryData = [];

        for(const query of [...this.userHideQueries]) {
            blockQueryData.push(query.toData());
        }
        
        const znData = JSON.stringify({
            accountData: accountData,
            accountType: this.userPlan.planId,
            concepts: storeConceptData,
            followQueryData: followQueryData,
            blockQueryData: blockQueryData,
            feedMode: this.userFeedMode
        });
        
        localStorage.znData = znData;
    }

    async resetStoredData() {
        localStorage.removeItem('znData');
    }
}