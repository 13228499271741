import React from 'react';

import SearchResults from './SearchResults.js';
import SpecificFeed from './SpecificFeed.js';
import AccountSettings from './AccountSettings.js';
import ContactPage from './ContactPage.js';
import HelpPage from './HelpPage.js';
import FollowBlockContext from './FollowBlockContext.js';
import Plans from './Plans.js';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import TermsPage from './TermsPage.js';
import { AboutPage } from './AboutPage.js';

class MainContent extends React.Component {
    render() {
        let element;
        
        if(this.props.display === 'userFeed' || this.props.display === 'tempFeed') {
            element = <SpecificFeed ui={ this.props.ui } accountType={ this.props.accountType } feedDisplay={ this.props.feedDisplay }
                shouldShowWelcomeBanner={ this.props.shouldShowWelcomeBanner }
                followQueries={ this.props.followQueries } blockQueries={ this.props.blockQueries }
                loadFilteredUserFeed={ this.props.loadFilteredUserFeed } loadRemoteFeed={ this.props.loadRemoteFeed }
                handleNavigationEvent={ this.props.handleNavigationEvent } runRefresh={ this.props.runRefresh }
                subscriptionNeedsAction={ this.props.subscriptionNeedsAction }/>;
        } else if(this.props.display === 'searchResults') {
            element = <SearchResults searchResults={ this.props.searchResults } loadFeed={ this.props.loadRemoteFeed }/>;
        } else if(this.props.display === 'accountSettings') {
            element = <AccountSettings ui={ this.props.ui } accountData={ this.props.accountData }
                accountType={ this.props.accountType } onSignIn={ this.props.onSignIn } signedOutDisplay="signIn"/>;
        } else if(this.props.display === 'firstVisit' || this.props.display === 'help') {
            element = <HelpPage/>;
        } else if(this.props.display === 'loading') {
            element = (
                <div className="middle-content-banner">
                    Loading...
                </div>
            );
        } else if(this.props.display === 'readableFeedNotFound') {
            element = (
                <div className="middle-content-banner">
                    Crossfeed is not currently able to understand the content of this website.
                    If the site has an RSS or Atom feed, enter the URL of that instead.
                </div>
            );
        } else if(this.props.display === 'createAccount') {
            element = <AccountSettings ui={ this.props.ui } accountData={ this.props.accountData }
                accountType={ this.props.accountType } onSignIn={ this.props.onSignIn } signedOutDisplay="createAccount"/>;
        } else if(this.props.display === 'plans') {
            element = <Plans ui={ this.props.ui } accountData={ this.props.accountData } accountType={ this.props.accountType }
            startCheckout={ this.props.startCheckout } visitBillingPortal={ this.props.visitBillingPortal }/>;
        } else if(this.props.display === 'about') {
            element = <AboutPage ui={ this.props.ui }/>;
        } else if(this.props.display === 'contact') {
            element = <ContactPage/>;
        } else if(this.props.display === 'privacyPolicy') {
            element = <PrivacyPolicyPage/>;
        } else if(this.props.display === 'terms') {
            element = <TermsPage/>;
        }

        return (
            <div className="middle-content">
                { element }
            </div>
        );
    }
}

MainContent.contextType = FollowBlockContext;

export default MainContent;