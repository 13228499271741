import React from 'react';

import Form from './Form.js';
import FollowBlockContext from './FollowBlockContext.js';
import { VerifyEmailAndHandleSignIn } from './VerifyEmail.js';
import { trackSignInAttempt, trackSignInFailure, trackSignInSuccess } from './CFAnalytics.js';

class StartSignIn extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            message: ''
        };
    }
    
    render() {
        const fields = [
            {
                label: 'Email address',
                id: 'email',
                type: 'text'
            },
            {
                label: 'Password (leave blank to sign in via confirmation email)',
                id: 'password',
                type: 'password'
            },
            
        ];

        if(this.props.ui.zn.getQueryCount() > 0) {
            fields.push({
                label: "Merge content on this device into your account",
                id: 'mergeQueries',
                type: 'checkbox'
            });
        }

        return (
            <div className="info-section">
                <div className="info-title">
                    Sign in
                </div>
                <Form submitText="Sign in" onSubmit={ this.props.onSubmit } message={ this.props.message }
                    fields={ fields }/>
            </div>
        );
    }
}

StartSignIn.contextType = FollowBlockContext;

class SignInVerifyEmail extends VerifyEmailAndHandleSignIn {
    messageAction = 'verify your email address and sign in'
    workingMessage = 'Signing in...'
    isSignIn = true

    async submitCode(enteredCode) {
        return await this.props.ui.zn.attemptEmailConfirmationAndSignIn(enteredCode, this.props.email, this.props.mergeQueries);
    }
}

class SignIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            display: 'start',
            message: '',
            email: null,
            password: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(formData) {
        const email = formData.email;
        const passwordEntry = formData.password;
        const password = passwordEntry.length > 0 ? passwordEntry : null;
        const mergeQueries = Boolean(formData.mergeQueries);
        this.setState({
            email: email,
            password: password
        });

        if(password) {
            await this.handleEmailAndPasswordSubmit(email, password, mergeQueries);
        } else {
            await this.handleEmailOnlySubmit(email, mergeQueries);
        }
    }

    async handleEmailAndPasswordSubmit(email, password, mergeQueries) {
        trackSignInAttempt(true, false);
        this.setState({
            message: 'Signing in...'
        });

        const signInAttempt = await this.props.ui.zn.attemptSignIn(email, password, mergeQueries);

        if(signInAttempt.wasSuccessful) {
            this.props.ui.handleSignIn();
            trackSignInSuccess(this.props.ui.zn.getUserId(), true, signInAttempt.isNewAccount);
        } else {
            this.setState({
                message: 'Incorrect email address or password.'
            });
            trackSignInFailure(true, 'incorrect credentials');
        }
    }

    async handleEmailOnlySubmit(email, mergeQueries) {
        trackSignInAttempt(true, true);
        this.setState({
            message: 'Sending email...'
        });

        const confirmationRequest = await this.props.ui.zn.requestSignInConfirmationEmail(email);

        if(confirmationRequest.wasSuccessful) {
            this.setState({
                display: 'verifyEmail',
                message: '',
                email: email,
                mergeQueries: mergeQueries
            });
        } else {
            const confirmationErrorMessage = confirmationRequest.message;

            this.setState({
                message: confirmationErrorMessage
            });
            trackSignInFailure(true, 'email auth failed');
        }
    }

    render() {
        if(this.state.display === 'start') {
            return <StartSignIn ui={ this.props.ui } message={ this.state.message } onSubmit={ this.handleSubmit }/>;
        } else if(this.state.display === 'verifyEmail') {
            return <SignInVerifyEmail ui={ this.props.ui } email={ this.state.email } password={ null }
                mergeQueries={ this.state.mergeQueries }
            />;
        }
    }
}

export default SignIn;