import React from 'react';

import SignIn from './SignIn.js';
import CreateAccount from './CreateAccount.js';
import Form from './Form.js';
import { VerifyEmailAndCallHandler } from './VerifyEmail.js';

class StartChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: ''
        };
    }

    render() {
        return (
            <div className="info-section">
                <div className="info-title">
                    Change password
                </div>
                <Form submitText="Change password" onSubmit={ this.props.onSubmit } message={ this.props.message }
                    fields={ [
                        {
                            label: 'Old password (leave blank to receive a confirmation email instead)',
                            id: 'oldPassword',
                            type: 'password'
                        },
                        {
                            label: 'New password (leave blank to sign in via confirmation email)',
                            id: 'newPassword',
                            type: 'password'
                        },
                        {
                            label: 'Confirm new password',
                            id: 'confirmNewPassword',
                            type: 'password'
                        }
                    ] }/>
            </div>
        );
    }
}

class ChangePasswordVerifyEmail extends VerifyEmailAndCallHandler {
    messageAction = 'verify your email address and update your password settings'
    workingMessage = 'Updating password settings...'

    async submitCode(enteredCode) {
        return await this.props.ui.zn.attemptEmailConfirmationAndPasswordChange(enteredCode, this.props.password);
    }
}

class ChangePasswordSection extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            display: 'start',
            message: '',
            oldPassword: '',
            newPassword: ''
        };

        this.handleChangedSuccessfully = this.handleChangedSuccessfully.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangedSuccessfully() {
        this.setState({
            display: 'start',
            message: 'Your password settings have been updated.',
            oldPassword: '',
            newPassword: ''
        });
    }

    async handleSubmit(formData) {
        if(formData.newPassword !== formData.confirmNewPassword) {
            this.setState({
                message: 'New passwords do not match.'
            });
        } else {
            const enteredOldPassword = formData.oldPassword;
            const oldPassword = enteredOldPassword.length > 0 ? enteredOldPassword : null;
            const enteredNewPassword = formData.newPassword;
            const newPassword = enteredNewPassword.length > 0 ? enteredNewPassword : null;

            this.setState({
                message: 'Changing password...',
                oldPassword: oldPassword,
                newPassword: newPassword
            });

            if(oldPassword) {
                await this.handleSubmitWithOldPassword();
            } else {
                await this.handleSubmitWithoutOldPassword();
            }
        }
    }

    async handleSubmitWithOldPassword() {
        const passwordChangeAttemptData = await this.props.ui.zn.attemptPasswordChange(this.state.oldPassword, this.state.newPassword);
        const responseWasReceived = passwordChangeAttemptData.success;
        const passwordChangeWasSuccessful = passwordChangeAttemptData.changed;

        let newMessage;

        if(responseWasReceived) {
            if(passwordChangeWasSuccessful) {
                newMessage = 'Password changed successfully.'
            } else {
                newMessage = 'Old password is incorrect.'
            }
        } else {
            newMessage = 'Something went wrong. Please sign in again.'
        }

        this.setState({
            message: newMessage
        });
    }

    async handleSubmitWithoutOldPassword() {
        this.setState({
            message: 'Sending confirmation email...'
        });

        const confirmationRequest = await this.props.ui.zn.requestPasswordChangeConfirmationEmail();

        if(confirmationRequest.wasSuccessful) {
            this.setState({
                display: 'verifyEmail',
                message: ''
            });
        } else {
            const confirmationErrorMessage = confirmationRequest.message;

            this.setState({
                message: confirmationErrorMessage
            });
        }
    }

    render() {
        if(this.state.display === 'start') {
            return <StartChangePassword message={ this.state.message } onSubmit={ this.handleSubmit }/>;
        } else if(this.state.display === 'verifyEmail') {
            return <ChangePasswordVerifyEmail ui={ this.props.ui } email={ this.props.ui.zn.getUserEmailAddress() } password={ this.state.newPassword }
                onVerificationSuccess={ this.handleChangedSuccessfully }/>;
        }
    }
}

class AccountSettings extends React.Component {
    render() {
        let content;
        
        if(this.props.accountData === null) {
            if(this.props.signedOutDisplay === "signIn") {
                content = [
                    <SignIn ui={ this.props.ui } onSignIn={ this.props.onSignIn } key="sign-in"/>
                ];
            } else {
                content = [
                    <CreateAccount ui={ this.props.ui } onSignIn={ this.props.onSignIn } key="create-account"/>
                ];
            }
        } else {
            content = [
                <div className="info-section" key="email">
                    <div className="info-title">
                        Email address
                    </div>
                    <span className="info-text">
                        { this.props.accountData.email }
                    </span>
                </div>,
                <div className="info-section" key="type">
                    <div className="info-title">
                        Account type
                    </div>
                    <span className="info-text">
                        { this.props.accountType.name }
                    </span>
                </div>,
                <ChangePasswordSection ui={ this.props.ui } key="change-password"/>
            ];
        }

        return (
            <div className="info-page">
                { content }
            </div>
        )
    }
}

export default AccountSettings;