import React from 'react';

class PaymentsNeedAction extends React.Component {
    render() {
        return (
            <div className="info-page">
                <div className="info-section">
                    <div className="info-title">
                        Your subscription needs attention
                    </div>
                    <div className="info-text">
                        There was an issue with your your subscription payment. Please resolve the issue to continue to receive
                        the benefits of your subscription or remove some queries to use the Basic plan.
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentsNeedAction;