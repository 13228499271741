import React from 'react';
import $ from 'jquery';

import FollowBlockContext from './FollowBlockContext.js';
import { conceptLabelComparator, nullishLastMaybeConceptLabelComparator } from './general.js';
import { CommasQueryLabel } from './Labels.js';
import { trackFeedFilter, trackSignOut } from './CFAnalytics.js';

class SidebarSection extends React.Component {
    constructor(props) {
        super(props);

        this.getTitle = this.getTitle.bind(this);
        this.getContent = this.getContent.bind(this);
    }
    
    render() {
        return (
            <div className="sidebar-section">
                { this.getTitle() }
                <div className="sidebar-section-content">
                    { this.getContent() }
                </div>
            </div>
        );
    }
}

class SidebarItem extends React.Component {
    constructor(props) {
        super(props);
        
        this.handleClick = this.handleClick.bind(this);
    }
}

class QuerySidebarItem extends SidebarItem {
    static comparator(a, b) {
        const aQuery = a.props.query;
        const bQuery = b.props.query;
        const topicsCompare = QuerySidebarItem.emptyLastConceptLabelsComparator(aQuery.topics, bQuery.topics);

        if(topicsCompare !== 0) {
            return topicsCompare
        }

        const authorsCompare = QuerySidebarItem.emptyLastConceptLabelsComparator(aQuery.authors, bQuery.authors);

        if(authorsCompare !== 0) {
            return authorsCompare;
        }

        const sourcesCompare = nullishLastMaybeConceptLabelComparator(aQuery.source, bQuery.source);
        return sourcesCompare;
    }

    static emptyLastConceptLabelsComparator(aList, bList) {
        const aItems = [...aList].sort(conceptLabelComparator);
        const bItems = [...bList].sort(conceptLabelComparator);

        const aItemsCount = aItems.length;
        const bItemsCount = bItems.length;

        const shortestLen = Math.min(aItemsCount, bItemsCount);

        for(let i=0; i<shortestLen; i++) {
            const aItem = aItems[i];
            const bItem = bItems[i];

            const labelComparison = conceptLabelComparator(aItem, bItem);

            if(labelComparison !== 0) {
                return labelComparison;
            }
        }

        if(aItemsCount > bItemsCount) {
            return -1;
        } else if(aItemsCount < bItemsCount) {
            return 1;
        } else {
            return 0;
        }
    }

    handleClick() {
        const query = this.props.query;
        this.props.ui.showFilteredUserFeedFromQuery(query);
        this.props.ui.handleSidebarAction();
        trackFeedFilter('sidebar', query);
    }

    render() {
        const query = this.props.query;
        const showQueryType = this.props.showQueryTypeLabel;
        let queryTypeLabel;

        if(showQueryType) {
            if(query.isPremium()) {
                queryTypeLabel = <div className='query-type-label text-italics text-blue'>Premium Search</div>;
            } else {
                queryTypeLabel = <div className='query-type-label text-italics text-white'>Source Search</div>;
            }
        }

        return (
            <div className={ 'sidebar-item query-sidebar-item' } onClick={ this.handleClick }>
                <CommasQueryLabel query={ query }/>
                { queryTypeLabel }
            </div>
        );
    }
}

class NewsSidebarSection extends SidebarSection {
    getTitle() {
        return (
            <div className="sidebar-section-heading text-white">
                { this.props.title }
            </div>
        );
    }
    
    getContent() {
        const entries = [];
        const ui = this.props.ui;

        for(const query of [...this.props.queries]) {
            entries.push(<QuerySidebarItem ui={ ui } query={ query } showQueryTypeLabel={ this.props.showQueryTypeLabels } key={ query.key() }/>);
        }

        entries.sort(QuerySidebarItem.comparator);
        return entries;
    }

    render() {
        const content = this.getContent();

        if(content.length > 0) {
            return (
                <div className="sidebar-section">
                    { this.getTitle() }
                    <div className="sidebar-section-content news-sidebar-section-content">
                        { content }
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

class MyFeedSidebarSection extends SidebarSection {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.ui.showUserFeed();
        this.props.onAction();
    }
    
    getTitle() {
        return (
            <div className="sidebar-section-heading text-blue clickable" onClick={ this.handleClick }>
                Your Crossfeed
            </div>
        );
    }
    
    getContent() {
        return [
            <NewsSidebarSection ui={ this.props.ui } title="Your Followed Searches" statusData={ {'followed': true, 'blocked': false} } loadFeed={ this.props.ui.loadFilteredUserFeed }
                queries={ this.props.followQueries } showQueryTypeLabels={ this.props.showQueryTypeLabels } onAction={ this.props.onAction } key="followed"/>,
            <NewsSidebarSection ui={ this.props.ui } title="Your Hidden Searches" statusData={ {'followed': false, 'blocked': true} } loadFeed={ this.props.ui.loadSingleQueryRemoteFeed }
                queries={ this.props.blockQueries } showQueryTypeLabels={ this.props.showQueryTypeLabels } onAction={ this.props.onAction } key="blocked"/>
        ];
    }
}

MyFeedSidebarSection.contextType = FollowBlockContext;

class AccountSidebarSection extends SidebarSection {
    constructor(props) {
        super(props);

        this.handlePlansClick = this.handlePlansClick.bind(this);
        this.handleAccountSettingsClick = this.handleAccountSettingsClick.bind(this);
        this.handleCreateAccountClick = this.handleCreateAccountClick.bind(this);
        this.handleSignOutClick = this.handleSignOutClick.bind(this);
    }

    handlePlansClick() {
        this.props.showPlans();
        this.props.onAction();
    }
    
    handleAccountSettingsClick() {
        this.props.loadAccountSettings();
        this.props.onAction();
    }

    handleCreateAccountClick() {
        this.props.loadCreateAccount();
        this.props.onAction();
    }

    handleSignOutClick() {
        this.props.ui.zn.signOut();
        this.props.onAction();
        trackSignOut();
    }
    
    getTitle() {
        return (
            <div className="sidebar-section-heading text-white clickable" onClick={ this.handleAccountSettingsClick }>
                Account
            </div>
        );
    }

    getContent() {
        let subscriptionsItem = (
            <div className="sidebar-item text-white clickable" onClick={ this.handlePlansClick } key="upgrade">
                Plans & Pricing
            </div>
        );

        if(this.props.accountData === null) {
            return [
                subscriptionsItem,
                <div className="sidebar-item text-white clickable" onClick={ this.handleAccountSettingsClick } key="signin">
                    Sign In
                </div>,
                <div className="sidebar-item text-white clickable" onClick={ this.handleCreateAccountClick } key="createAccount">
                    Create Account
                </div>
            ];
        } else {
            return [
                subscriptionsItem,
                <div className="sidebar-item text-white clickable" onClick={ this.handleAccountSettingsClick } key="account">
                    Settings
                </div>,
                <div className="sidebar-item text-white clickable" onClick={ this.handleSignOutClick } key="signout">
                    Sign Out
                </div>
            ];
        }
    }
}

AccountSidebarSection.contextType = FollowBlockContext;

class InformationSidebarSection extends SidebarSection {
    constructor(props) {
        super(props);

        this.handleAboutClick = this.handleAboutClick.bind(this);
        this.handleContactClick = this.handleContactClick.bind(this);
        this.handleInstructionsClick = this.handleInstructionsClick.bind(this);
        this.handlePrivacyPolicyClick = this.handlePrivacyPolicyClick.bind(this);
        this.handleTermsClick = this.handleTermsClick.bind(this);
    }

    handleAboutClick() {
        this.props.ui.showAboutPage();
        this.props.onAction();
    }
    
    handleContactClick() {
        this.props.ui.showContactPage();
        this.props.onAction();
    }

    handleInstructionsClick() {
        this.props.showInstructionsPage();
        this.props.onAction();
    }

    handlePrivacyPolicyClick() {
        this.props.showPrivacyPolicyPage();
        this.props.onAction();
    }

    handleTermsClick() {
        this.props.showTermsPage();
        this.props.onAction();
    }
    
    getTitle() {
        return (
            <div className="sidebar-section-heading text-white">
                Information
            </div>
        );
    }
    
    getContent() {
        return [
            <div className="sidebar-item text-white clickable" onClick={ this.handleAboutClick } key="about">
                About Crossfeed
            </div>,
            <div className="sidebar-item text-white clickable" onClick={ this.handleInstructionsClick } key="help">
                Instructions
            </div>,
            <div className="sidebar-item text-white clickable" onClick={ this.handleContactClick } key="contact">
                Contact
            </div>,
            <div className="sidebar-item text-white clickable" onClick={ this.handleTermsClick } key="terms">
                Terms of Use
            </div>,
            <div className="sidebar-item text-white clickable" onClick={ this.handlePrivacyPolicyClick } key="privacyPolicy">
                Privacy Policy
            </div>
        ];
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props);

        this.onSidebarTouchStart = this.onSidebarTouchStart.bind(this);
    }
    
    componentDidMount() {
        // Prevent body from scrolling through sidebar if sidebar doesn't need to scroll.
        $('#sidebar').on('touchmove', this.onSidebarTouchStart);
    }

    componentWillUnmount() {
        $('#sidebar').off('touchmove', this.onSidebarTouchStart);
    }

    onSidebarTouchStart(event) {
        if($('#sidebar').height() >= $('#sidebar-content').height()) {
            event.preventDefault();
        }
    }
    
    render() {
        let sidebarClasses;

        if(this.props.open) {
            sidebarClasses = 'sidebar sidebar-open';
        } else {
            sidebarClasses = 'sidebar';
        }
        
        return (
            <div className="sidebar-container">
                <div id="sidebar" className={ sidebarClasses }>
                    <div id="sidebar-content" className="sidebar-content">
                        <MyFeedSidebarSection ui={ this.props.ui }
                            followQueries={ this.props.followQueries } blockQueries={ this.props.blockQueries }
                            showQueryTypeLabels={ this.props.showQueryTypeLabels } onAction={ this.props.ui.handleSidebarAction }/>
                        <AccountSidebarSection ui={ this.props.ui } accountData={ this.props.accountData } accountType={ this.props.accountType }
                            loadAccountSettings={ this.props.loadAccountSettings } loadCreateAccount={ this.props.loadCreateAccount }
                            showPlans={ this.props.showPlans } onAction={ this.props.ui.handleSidebarAction }
                            visitBillingPortal={ this.props.visitBillingPortal }/>
                        <InformationSidebarSection ui={ this.props.ui } showInstructionsPage={ this.props.showInstructionsPage }
                            showPrivacyPolicyPage={ this.props.showPrivacyPolicyPage } showTermsPage={ this.props.showTermsPage }
                            onAction={ this.props.ui.handleSidebarAction }/>
                    </div>
                </div>
                <div className="sidebar-content-cover" onClick={ this.props.toggleSidebar }/>
            </div>
        );
    }
}

Sidebar.contextType = FollowBlockContext;

export default Sidebar;