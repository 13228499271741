import React from 'react'

import { getNormalizedUrlTextFromText, SequenceTree } from './general.js';
import { TopicLabel, SourceLabel, PhraseQueryLabel, AuthorLabel } from './Labels.js';
import { trackSearch } from './CFAnalytics.js';
import ZatemyLogo from './ZatemyLogo.js';

class SearchSuggestionsTree extends SequenceTree {
    textAndQueryToSequence(query, text) {
        const seq = [];

        // If there are selected items already, start the search sequence with their query key.
        if(query) {
            seq.push(query.key());
        }

        seq.push(...text);  // After the query key, search by the text.

        return seq;
    }
    
    addSearch(query, text, item) {
        this.addSequence(this.textAndQueryToSequence(query, text), item);
    }

    hasExactMatch(query, text) {
        return super.hasExactMatch(this.textAndQueryToSequence(query, text));
    }

    getClosestMatchingSearchItem(query, text) {
        return this.getClosestMatchingSequenceItem(this.textAndQueryToSequence(query, text));
    }
}

class Suggestion extends React.Component {
    constructor(props) {
        super(props);

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {}

    handleMouseDown(event) {
        event.preventDefault();
    }
    
    render() {
        return (
            <div className="search-suggestion" onMouseDown={ this.handleMouseDown } onClick={ this.handleClick }>
                { this.getContent() }
            </div>
        );
    }
}

class SearchSuggestion extends Suggestion {
    getContent() {
        return (
            <PhraseQueryLabel query={ this.props.query }/>
        );
    }

    handleClick() {
        this.props.onSuggestionSelect(this.props.query);
    }
}

class CustomSourceSuggestion extends Suggestion {
    getContent() {
        return (
            <span>Select custom source</span>
        );
    }

    async handleClick() {
        this.props.ui.showLoading();
        const query = await this.props.ui.zn.getCustomSourceQueryFromUrl(this.props.url);
        this.props.onSuggestionSelect(query);
    }
}

class NoSuggestions extends Suggestion {
    getContent() {
        return (
            <span>No results found</span>
        )
    }
}

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '',
            selected: [],
            isFocused: false,
            suggestions: [],
            url: null,
            currentCombinedQuery: null,
            typingTimeoutIsActive: false,
            numInProgressSuggestionRequests: 0
        };

        this.inputRef = React.createRef();
        this.typingTimeout = null;
        this.searchSuggestionsTree = new SearchSuggestionsTree([]);

        this.showSearchResults = this.showSearchResults.bind(this);
        this.focusAndSelectInput = this.focusAndSelectInput.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTypingTimeoutComplete = this.handleTypingTimeoutComplete.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSuggestionSelect = this.handleSuggestionSelect.bind(this);
    }

    anAuthorHasBeenSelected() {
        for(const query of this.state.selected) {
            if(query.authors.length > 0) {
                return true;
            }
        }

        return false;
    }

    aSourceHasBeenSelected() {
        return Boolean(this.getSelectedSource());
    }

    getSelectedSource() {
        for(const query of this.state.selected) {
            if(query.source) {
                return query.source;
            }
        }

        return null;
    }

    showSearchResults() {
        this.props.ui.showSearchFeedFromQuery(this.state.currentCombinedQuery);

        this.props.ui.handleSearchEvent();
    }
    
    focusAndSelectInput() {
        this.inputRef.current.focus();
        this.inputRef.current.select();
    }

    blurFromInput() {
        this.inputRef.current.blur();
    }

    handleFocus(event) {
        this.props.ui.handleSearchFocus();
        
        const conceptsAreSelected = this.state.selected.length > 0;
        
        if(conceptsAreSelected) {
            this.props.ui.showSearchFeedFromQuery(this.state.currentCombinedQuery);
        }
        
        this.setState({
            isFocused: true
        });
    }

    handleBlur(event) {
        this.setState({
            isFocused: false
        });
    }
    
    handleTextChange(event) {
        const text = event.target.value;
        
        const stateUpdate = {
            text: text
        };

        // Use timeouts to wait until 500ms has passed after the user stops typing to make a request for suggestions.
        clearTimeout(this.typingTimeout);
        stateUpdate.typingTimeoutIsActive = false;
        const allowCustomSourceSelection = !this.aSourceHasBeenSelected() && !this.anAuthorHasBeenSelected();

        if(text.length > 0) {
            let url;

            if(allowCustomSourceSelection && (url = getNormalizedUrlTextFromText(text))) {
                stateUpdate.url = url;
            } else {
                stateUpdate.url = null;
                
                const searchSuggestionsTree = this.searchSuggestionsTree;
            
                // If suggestions for exactly the current search are not already known, set a timeout to potentially request them.
                if(!searchSuggestionsTree.hasExactMatch(this.state.currentCombinedQuery, text)) {
                    this.typingTimeout = setTimeout(this.handleTypingTimeoutComplete, 500);
                    stateUpdate.typingTimeoutIsActive = true;
                }

                // Display the suggestions for the most related string for which suggestions are already known.
                stateUpdate.suggestions = searchSuggestionsTree.getClosestMatchingSearchItem(this.state.currentCombinedQuery, text);
                }
        } else {
            stateUpdate.suggestions = [];
        }

        this.setState(stateUpdate);
    }

    async handleTypingTimeoutComplete() {
        const inputText = this.state.text;
        const inputQuery = this.state.currentCombinedQuery;

        this.setState({
            numInProgressSuggestionRequests: this.state.numInProgressSuggestionRequests + 1,
            typingTimeoutIsActive: false
        });
        const responseSuggestions = await this.props.ui.zn.getSearchSuggestionsFromQueryAndText(inputQuery, inputText);
        this.setState({
            numInProgressSuggestionRequests: this.state.numInProgressSuggestionRequests - 1
        });

        const searchSuggestionsTree = this.searchSuggestionsTree;
        searchSuggestionsTree.addSearch(inputQuery, inputText, responseSuggestions);

        // Show the most relevant currently known suggestions, regardless of whether they are associated with this response.
        this.setState({
            suggestions: searchSuggestionsTree.getClosestMatchingSearchItem(this.state.currentCombinedQuery, this.state.text)
        });
    }

    handleKeyDown(event) {
        if(this.state.suggestions.length > 0 && event.key === 'Enter') {
            // When enter is pressed, select the top suggestion.
            this.handleSuggestionSelect(this.state.suggestions[0]);
        } else if(this.state.text.length === 0 && this.state.selected.length > 0 && event.key === 'Backspace') {
            // When backspace is pressed with no input text to remove, remove the last selected item.
            const updatedSelected = this.state.selected.slice(0, -1);
            
            let afterRemovedFunc = null;

            if(updatedSelected.length > 0) {
                afterRemovedFunc = this.showSearchResults;
            }
            
            this.setState({
                selected: updatedSelected,
                currentCombinedQuery: this.props.ui.zn.intersectQueries(updatedSelected)
            }, afterRemovedFunc);
        }
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    handleSuggestionSelect(query) {
        if(query) {
            const newSelected = [...this.state.selected];
            newSelected.push(query)
            this.setState({
                text: '',
                selected: newSelected,
                suggestions: [],
                url: null,
                currentCombinedQuery: this.props.ui.zn.intersectQueries(newSelected)
            }, this.showSearchResults);
            this.focusAndSelectInput();
            trackSearch(query);
        } else {
            this.setState({
                suggestions: [],
            });
            this.props.ui.showReadableFeedNotFound();
            this.blurFromInput();
        }
    }
    
    render() {
        const selectedDivs = [];
        let inputText;
        const suggestionDivs = [];
        let searchBarClasses = "search-bar";
        const isFocused = this.state.isFocused;
        const placeholder = this.aSourceHasBeenSelected() ? "Enter a keyword" : "Enter a URL or news keyword";

        if(isFocused) {
            searchBarClasses += " search-bar-focused";

            this.state.selected.forEach((query, index) => {
                let label;

                if(query.source) {
                    label = <SourceLabel source={ query.source } key="label"/>;
                } else if(query.topics.length === 1) {
                    label = <TopicLabel topic={ query.topics[0] } key="label"/>;
                } else {
                    label = <AuthorLabel author={ query.authors[0] } key="label"/>;
                }

                selectedDivs.push(
                    <div className="search-selected-item" key={ index }>
                        { label }
                        <span key="sep">,&nbsp;</span>
                    </div>
                );
            });

            inputText = this.state.text;

            if(inputText.length > 0) {
                const url = this.state.url;

                if(url) {
                    suggestionDivs.push(<CustomSourceSuggestion ui={ this.props.ui } url={ url } onSuggestionSelect={ this.handleSuggestionSelect }
                        key='customSource'/>);
                } else if(this.state.suggestions.length > 0) {
                    this.state.suggestions.forEach((query, index) => {
                        suggestionDivs.push(
                            <SearchSuggestion query={ query } onSuggestionSelect={ this.handleSuggestionSelect } key={ index }/>
                        );
                    });
                } else if(!this.state.typingTimeoutIsActive && this.state.numInProgressSuggestionRequests === 0) {
                    suggestionDivs.push(
                        <NoSuggestions key="noSuggestions"/>
                    );
                }
            }
        } else {
            inputText = '';
        }
        
        return (
            <div className="header-interface-search">
                <div className="search-bar-placeholder">
                    <form className={ searchBarClasses } onSubmit={ this.handleSubmit }>
                        <div className="search-bar-content" onClick={ this.focusAndSelectInput }>
                            { selectedDivs }
                            <input ref={ this.inputRef } className="search-input" type="text" name="query" aria-label="Search" value={ inputText }
                                autoComplete="off" placeholder={ isFocused ? placeholder : null } onFocus={ this.handleFocus } onBlur={ this.handleBlur }
                                onChange={ this.handleTextChange } onKeyDown={ this.handleKeyDown }/>
                        </div>
                        <button type="submit" className="search-button">
                            search
                        </button>
                        <div className="search-suggestions">
                            { suggestionDivs }
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function MainHeader(props) {
    function handleBackButtonClick() {
        props.ui.navigateBack();
    }

    function handleLogoClick() {
        props.ui.showUserFeed();
        props.ui.handleNavigationEvent();
    }

    return (
        <header>
            <div className="menu-button" onClick={ props.toggleSidebar }>
                menu
            </div>
            <div className="header-logo-container">
                <ZatemyLogo onClick={ handleLogoClick }/>
            </div>
            <div className="header-interface">
                <div className="header-interface-left">
                    {
                        props.showBackButton ?
                            <div className="back-button" onClick={ handleBackButtonClick }>
                                arrow_back_ios
                            </div>
                            : null
                    }
                </div>
                <div className="header-interface-right">
                    <Search ui={ props.ui } onSearchData={ props.onSearchData }
                        showRemoteFeed={ props.showRemoteFeed }/>
                    <div className="user-info">
                        { props.email }
                    </div>
                </div>
            </div>
        </header>
    );
}

export default MainHeader;