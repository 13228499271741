import { CustomSourceContentItem } from "./Feed";
import { domParser, getNormalizedUrlTextFromText } from "./general";

class FeedParseResult {
    constructor(successful, contentItems, sourceName) {
        this.successful = successful;
        this.contentItems = contentItems;
        this.sourceName = sourceName;
    }
}

export function parseFeedText(text, source) {
    const feedElement = getFeedElementFromText(text);

    if(feedElement) {
        if(feedElement.tagName === 'rss') {
            return parseRssElement(feedElement, source);
        } else if(feedElement.tagName === 'feed') {
            return parseAtomElement(feedElement, source);
        }
    }

    return new FeedParseResult(false);
}

function parseRssElement(rssElement, source) {
    const channelElement = rssElement.querySelector(':scope > channel');

    if(channelElement) {
        const channelTitle = getFeedTitleFromElement(channelElement);
        let sourceName;

        if(channelTitle) {
            sourceName = channelTitle;
        }

        const contentItems = getContentItemsFromChannelElement(channelElement, source);
        return new FeedParseResult(true, contentItems, sourceName);
    } else {
        return new FeedParseResult(false);
    }
}

function getContentDocFromItemElement(itemElement) {
    return getHtmlDocFromItemElementAndQuerySelector(itemElement, ':scope > encoded, :scope > content');
}

function getContentItemFromAtomOrRssItemElement(itemElement, source) {
    const titleElement = itemElement.querySelector(':scope > title');
    const urlElement = itemElement.querySelector(':scope > link');
    const pubDateElement = itemElement.querySelector(':scope > pubDate, :scope > published')
        ?? itemElement.querySelector(':scope > updated');

    if(titleElement && urlElement && pubDateElement) {
        const title = titleElement.textContent;
        const url = getNormalizedUrlTextFromText(urlElement.getAttribute('href') ?? urlElement.textContent);
        const pubTime = Date.parse(pubDateElement.textContent) / 1000;

        if(title && url && pubTime) {
            const contentDoc = getContentDocFromItemElement(itemElement);
            const descriptionDoc = getDescriptionDocFromItemElement(itemElement);
            const imageUrl = getImageUrlFromAtomOrRssItemElement(itemElement)
                ?? (contentDoc ? getFirstImageUrlFromHtmlDoc(contentDoc, url) : null)
                ?? (descriptionDoc ? getFirstImageUrlFromHtmlDoc(descriptionDoc, url) : null);
            const contentItem = new CustomSourceContentItem(url, title, imageUrl, source, pubTime, [], []);
            contentItem.contentDoc = contentDoc;
            contentItem.descriptionDoc = descriptionDoc;
            return contentItem;
        }
    }

    return null;
}

function getDescriptionDocFromItemElement(itemElement) {
    const descDoc = getHtmlDocFromItemElementAndQuerySelector(itemElement, 'description');
    return descDoc;
}

function getFeedElementFromText(text) {
    const doc = domParser.parseFromString(text, 'text/xml');
    const feedElement = doc.querySelector('rss, feed');
    return feedElement;
}

function getFeedTitleFromElement(channelElement) {
    const channelTitleElement = channelElement.querySelector(':scope > title');

    if(channelTitleElement) {
        const channelTitle = channelTitleElement.textContent;

        if(channelTitle.length > 0) {
            return channelTitle;
        }
    }

    return null;
}

function getFirstImageUrlFromHtmlDoc(doc, itemUrl) {
    const firstContentImg = doc.querySelector('img[src]');

    if(firstContentImg) {
        const firstContentImgUrl = getNormalizedUrlTextFromText(firstContentImg.getAttribute('src'), itemUrl);
        return firstContentImgUrl;
    }

    return null;
}

function getHtmlDocFromItemElementAndQuerySelector(itemElement, querySelector) {
    const elementText = itemElement.querySelector(querySelector)?.firstChild?.wholeText;
    const doc = elementText ? domParser.parseFromString(elementText, 'text/html') : null;
    return doc;
}

function getContentItemsFromElementAndEntryTagName(element, source, tagName) {
    const contentItems = [];
    const itemElements = element.querySelectorAll(':scope > ' + tagName);

    for(const itemElement of itemElements) {
        const contentItem = getContentItemFromAtomOrRssItemElement(itemElement, source);

        if(contentItem) {
            contentItems.push(contentItem);
        }
    }

    return contentItems;
}

function getContentItemsFromChannelElement(channelElement, source) {
    return getContentItemsFromElementAndEntryTagName(channelElement, source, 'item');
}

function getContentItemsFromAtomElement(channelElement, source) {
    return getContentItemsFromElementAndEntryTagName(channelElement, source, 'entry');
}

function getImageUrlFromAtomOrRssItemElement(itemElement) {
    const mediaContentImage = itemElement.querySelector(":scope > content[medium='image'], :scope > group > thumbnail");

    if(mediaContentImage) {
        const mediaContentImageUrl = getNormalizedUrlTextFromText(mediaContentImage.getAttribute('url'));

        if(mediaContentImageUrl) {
            return mediaContentImageUrl;
        }
    }

    return null;
}

function parseAtomElement(atomElement, source) {
    const contentItems = getContentItemsFromAtomElement(atomElement, source);
    const title = getFeedTitleFromElement(atomElement);
    return new FeedParseResult(true, contentItems, title);
}

export function textIsFeed(text) {
    return Boolean(getFeedElementFromText(text));
}