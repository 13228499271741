import $ from 'jquery';
import { DataManagerWithUserInfo } from './DataManager';

import { parseFeedText, textIsFeed } from './feed_parsing';
import { domParser, getNormalizedUrlTextFromText, statusCodeMeansSuccess } from './general';

const CORS_PROXY_ENDPOINT = process.env.REACT_APP_CORS_PROXY_ENDPOINT;

export class ThirdPartyDataManager extends DataManagerWithUserInfo {
    constructor() {
        super();

        this.sourceToContentItemsCache = {};
        this.urlToResponseDataCache = {};
    }

    cacheContentItemsFromSource(contentItems, source) {
        this.sourceToContentItemsCache[source.id] = contentItems;
    }

    cacheResponseFromUrl(responseData, url) {
        this.urlToResponseDataCache[url] = responseData;
    }

    async getAndCacheFreshContentItemsFromSource(source) {
        const [returnedText, parseResult] = await this.getAndParseContentFromSourceAndUrl(source, source.url);

        if(returnedText) {
            if(parseResult.successful) {
                this.handleSuccessfulSourceFeedParse(parseResult, source);
                return parseResult.contentItems;
            }
        }

        return [];
    }

    async getAndCacheReponseFromUrl(url) {
        try {
            const responseData = await $.get({
                url: CORS_PROXY_ENDPOINT,
                dataType: 'json',
                timeout: 4000,
                data: {
                    urls: JSON.stringify([url.toString()])
                }
            });
            const urlResponseData = responseData[url];
            this.cacheResponseFromUrl(urlResponseData, url);
            return urlResponseData;
        } catch (_) {
            return { content: '' };
        }
    }

    async getAndParseContentFromSourceAndUrl(source, url) {
        const responseData = await this.getResponseFromUrl(url);
        const wasSuccessful = this.responseWasSuccessful(responseData);

        if(wasSuccessful) {
            const text = responseData.content;
            const parseResult = parseFeedText(text, source);
            return [text, parseResult];
        } else {
            return [undefined, undefined];
        }
    }

    getCachedContentItemsFromSource(source) {
        return this.sourceToContentItemsCache[source.id] ?? null;
    }

    getCachedResponseFromUrl(url) {
        return this.urlToResponseDataCache[url] ?? null;
    }
    
    async getContentItemsFromQueries(showQueries, hideQueries) {
        const uniqueSources = this.getSourcesSetFromQueries(showQueries);
        return await this.getContentItemsFromSources(uniqueSources);
    }

    async getContentItemsFromSource(source) {
        const cachedContentItems = this.getCachedContentItemsFromSource(source);

        if(cachedContentItems === null) {
            return await this.getAndCacheFreshContentItemsFromSource(source);
        } else {
            return cachedContentItems;
        }
    }

    async getContentItemsFromSources(sources) {
        const requestPromises = [];

        for(const source of [...sources]) {
            requestPromises.push(this.getContentItemsFromSource(source));
        }

        const requestResults = [];

        for(const promise of requestPromises) {
            requestResults.push(await promise);
        }

        const contentItems = requestResults.flat();
        //contentItems.sort();
        return contentItems;
    }

    getFeedAlternateUrlFromText(text, lastUrl) {
        const doc = domParser.parseFromString(text, 'text/html');
        const altElement = doc.querySelector('link[rel="alternate"][type="application/rss+xml"][href], link[rel="alternate"][type="application/atom+xml"][href]');

        if(altElement) {
            const altUrl = getNormalizedUrlTextFromText(altElement.getAttribute('href'), lastUrl);
            return altUrl ?? undefined;
        }

        return undefined;
    }

    async getFinalFeedUrl(url, pathDepth=1) {
        const response = await this.getResponseFromUrl(url);

        if(this.responseWasSuccessful(response)) {
            if(textIsFeed(response.content)) {
                return url;
            } else if(pathDepth > 0) {
                const textAltUrl = this.getFeedAlternateUrlFromText(response.content, url);

                if(textAltUrl) {
                    return await this.getFinalFeedUrl(textAltUrl, pathDepth - 1);
                } else {
                    const nextUrlToCheck = this.getNextUrlTextToCheck(url);
                    return await this.getFinalFeedUrl(nextUrlToCheck, pathDepth - 1);
                }
            }
        }

        return null;
    }

    getNextUrlTextToCheck(lastUrlText) {
        // Note: Any trailing slash in URL has been normalized out by getNormalizedUrlTextFromText.
        const lastUrlHost = new URL(lastUrlText).host;
        let newSuffix;

        if(lastUrlHost.endsWith('reddit.com')) {
            newSuffix = '.rss';
        } else {
            newSuffix = '/feed';
        }

        return getNormalizedUrlTextFromText(lastUrlText + newSuffix);
    }

    async getResponseFromUrl(url) {
        const cachedResponse = this.getCachedResponseFromUrl(url);

        if(cachedResponse) {
            return cachedResponse;
        } else {
            return await this.getAndCacheReponseFromUrl(url);
        }
    }

    getSourcesSetFromQueries(queries) {
        const sources = new Set();

        for(const query of [...queries]) {
            sources.add(query.source);
        }

        return sources;
    }

    async read() {
        this.userFeedContentItems = this.userFollowQueries.size > 0 ? await this.getContentItemsFromQueries(this.userFollowQueries) : [];
    }

    handleSuccessfulSourceFeedParse(parseResult, source) {
        const sourceName = parseResult.sourceName;

        if(sourceName) {
            source.label = sourceName;
        }

        this.cacheContentItemsFromSource(parseResult.contentItems, source);
    }

    resetCaches() {
        this.resetContentItemsCache();
        this.resetResponseCache();
    }

    resetContentItemsCache() {
        this.sourceToContentItemsCache = {};
    }

    resetResponseCache() {
        this.urlToResponseDataCache = {};
    }

    responseWasSuccessful(responseData) {
        return statusCodeMeansSuccess(responseData.status);
    }
}