import React from 'react';

class TermsPage extends React.Component {
    render() {
        return (
            <div className="info-page">
                <div className="info-section">
                    <div className="info-title">
                        Terms of Use
                    </div>
                    <div className="info-text">
                        <p>
                            Last updated February 27, 2022
                        </p>
                        <p>
                            When we say “Zatemy”, “Company”, “we”, “our”, or “us” in this document, we are referring to Zatemy LLC. When we say “Services”, we mean any product, website, application, or service created and maintained by Zatemy LLC, whether delivered within a web browser, desktop application, mobile application, or another format. When we say “You” or “your”, we are referring to the people or organizations that own an account with one or more of our Services. Zatemy may make updates and changes to these Terms of Use in the future. You can see these updates and changes by returning to this page.
                        </p>
                        <p>
                            When you use our Services, now or in the future, you are agreeing to the latest Terms of Use. That's true for any of our existing and future products and all features that we add to our Services over time. There may be times where we do not exercise or enforce any right or provision of the Terms of Use; in doing so, we are not waiving that right or provision. <span className="text-bold">These terms do contain a limitation of our liability.</span>
                        </p>
                        <p>
                            If you violate any of the terms, we may terminate your account.
                        </p>
                        <p className="info-subtitle">
                            Account Terms
                        </p>
                        <ol>
                            <li>
                                You are responsible for maintaining the security of your account and password. The Company cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.
                            </li>
                            <li>
                                You are responsible for all content posted and activity that occurs under your account. That includes content posted by others who either: (a) have access to your login credentials; or (b) have their own logins under your account.
                            </li>
                            <li>
                                You must be a human. Accounts registered by “bots” or other automated methods are not permitted.
                            </li>
                        </ol>
                        <p className="info-subtitle">
                            Payment, Refunds, and Plan Changes
                        </p>
                        <ol>
                            <li>
                                If you are upgrading from a free plan to a paid plan, your billing cycle starts on the day of upgrade. For other upgrades or downgrades in plan level, the new rate will start from the next billing cycle. Proration may be charged on the day of the upgrade or downgrade. You are solely responsible for properly upgrading, downgrading, and canceling subscriptions for your account.
                            </li>
                            <li>
                                All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities. In some cases, those taxes may be collected by our payment processor on behalf of the taxing authority and remitted to taxing authorities. Otherwise, you are responsible for payment of all taxes, levies, or duties.
                            </li>
                            <li>
                                Refunds may be processed at the sole discretion of the Company.
                            </li>
                        </ol>
                        <p className="info-subtitle">
                            Uptime, Security, and Privacy
                        </p>
                        <ol>
                            <li>
                                Your use of the Services is at your sole risk. We provide these Services on an “as is” and “as available” basis.
                            </li>
                            <li>
                                We reserve the right to prevent or throttle your usage or disable your account if your usage negatively impacts or has the potential to negatively impact the performance or availability of the Services for other customers.
                            </li>
                            <li>
                                When you use our Services, you entrust us with your data. We take that trust to heart. You agree that Zatemy and its subprocessors may process your data as described in our Privacy Policy.
                            </li>
                        </ol>
                        <p className="info-subtitle">
                            Copyright and Content Ownership
                        </p>
                        <ol>
                            <li>
                                All content posted on the Services must comply with U.S. copyright law.
                            </li>
                            <li>
                                We claim no intellectual property rights over the material you provide to the Services. All materials uploaded remain yours.
                            </li>
                            <li>
                                The Services may sometimes provide links to content on websites not owned by Zatemy and text, images, or other media associated with that content. We claim no ownership rights over media owned by those websites.
                            </li>
                            <li>
                                We do not pre-screen content, but reserve the right (but not the obligation) in our sole discretion to refuse or remove any content that is available via the Service.
                            </li>
                            <li>
                                The names, look, and feel of the Services are copyright© to the Company. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design elements without express written permission from the Company. You must request permission to use the Company's logo or any Service logos for promotional purposes. We reserve the right to rescind this permission at any time.
                            </li>
                            <li>
                                You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use of the Services, or access to the Services without the express written permission by the Company.
                            </li>
                            <li>
                                You must not modify another website so as to falsely imply that it is associated with the Services or the Company.
                            </li>
                        </ol>
                        <p className="info-subtitle">
                            Features and Bugs
                        </p>
                        <p>
                            We make no guarantees that our Services will meet your specific requirements or expectations. As with any software, our Services inevitably have some bugs. Not all reported bugs will get fixed and we don't guarantee error-free Services.
                        </p>
                        <p className="info-subtitle">
                            Liability
                        </p>
                        <p className="text-bold">
                            You expressly understand and agree that the Company shall not be liable, in law or in equity, to you or to any third party for any direct, indirect, incidental, lost profits, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Services; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to this Terms of Use or the Services, whether as a breach of contract, tort (including negligence whether active or passive), or any other theory of liability.
                        </p>
                        <p className="text-italics">
                            Adapted from the <a href="https://github.com/basecamp/policies" target="_blank" rel="noopener noreferrer">Basecamp open-source policies</a> / <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noopener noreferrer">CC BY 4.0</a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default TermsPage;