import { DataManagerWithUserInfo } from "./DataManager";
import { ExternalDataManager } from "./ExternalDataManager";
import { Feed } from "./Feed";
import { Concept, configExceedsPlan, CONFIRM_PASSWORD_CHANGE, CONFIRM_SIGN_IN, CONFIRM_SIGN_UP, CustomSourceConcept, FREE_USER, getShowAndHideQuerySetsKey, Query, updateIdToConceptObjWithConceptsData } from "./general";

export class ZNCore extends DataManagerWithUserInfo {
    constructor(ui) {
        super();

        this.notifyExternalDataManagerHasUpdate = this.notifyExternalDataManagerHasUpdate.bind(this);
        
        this.ui = ui;

        this.concepts = {};
        this.queries = {};
        
        this.resetQuerySetsToContentItemsCache();
        
        const externalDataManager = new ExternalDataManager(this, this.notifyExternalDataManagerHasUpdate);
        externalDataManager.readLocalOnly();
        this.externalDataManager = externalDataManager;

        this.updateAttributesFromExternalDataManager();
    }

    actionOnQueryWouldExceedEffectivePlan(query, actionName) {
        const effectivePlan = this.getEffectiveUserPlan();
        const candidateFollowQueriesSet = new Set(this.userFollowQueries);
        const candidateHideQueriesSet = new Set(this.userHideQueries);

        if(actionName === 'follow') {
            candidateFollowQueriesSet.add(query);
            candidateHideQueriesSet.delete(query);
        } else {
            candidateFollowQueriesSet.delete(query);
            candidateHideQueriesSet.add(query);
        }

        return configExceedsPlan(candidateFollowQueriesSet, candidateHideQueriesSet, effectivePlan);
    }

    async attemptEmailConfirmation(confirmationCode, emailAddress, setPassword, password=null, includeQueryData=false) {
        const includedQueryData = includeQueryData ? JSON.stringify(this.getAllQueryData()) : null;
        const attempt = await this.externalDataManager.attemptEmailConfirmation(confirmationCode,
            emailAddress, setPassword, password, includedQueryData);

        if(attempt.wasSuccessful) {
            this.updateAttributesFromExternalDataManager();
        }

        return attempt;
    }

    async attemptEmailConfirmationAndAccountCreation(confirmationCode, emailAddress, password) {
        return await this.attemptEmailConfirmation(confirmationCode, emailAddress, true, password, true);
    }

    async attemptEmailConfirmationAndPasswordChange(confirmationCode, newPassword) {
        return await this.attemptEmailConfirmation(confirmationCode, null, true, newPassword);
    }

    async attemptEmailConfirmationAndSignIn(confirmationCode, emailAddress, mergeQueries) {
        return await this.attemptEmailConfirmation(confirmationCode, emailAddress, false, null, mergeQueries);
    }

    async attemptPasswordChange(oldPassword, newPassword) {
        return await this.externalDataManager.attemptPasswordChange(oldPassword, newPassword);
    }

    async attemptSignIn(emailAddress, password, keepExistingQueries) {
        const signInAttempt = await this.externalDataManager.attemptSignIn(emailAddress, password, keepExistingQueries);

        if(signInAttempt.wasSuccessful) {
            this.updateAttributesFromExternalDataManager();
        }

        return signInAttempt;
    }

    cacheContentItemsFromCombinedKey(contentItems, combinedKey) {
        this.querySetsToContentItemsCache[combinedKey] = contentItems;
    }

    cacheContentItemsFromQuerySets(contentItems, showQueries, hideQueries) {
        const combinedKey = getShowAndHideQuerySetsKey(showQueries, hideQueries);
        this.cacheContentItemsFromCombinedKey(contentItems, combinedKey);
    }

    cacheNestedFeedContentItems(nestedFeed, hideQueries) {
        for(const subfeed of nestedFeed.items) {
            this.cacheContentItemsFromQuerySets(subfeed.contentItems, new Set([subfeed.query]), hideQueries);
        }
    }

    cacheNestedFeedContentItemsAsUserFeedIntersections(nestedFeed) {
        for(const subfeed of nestedFeed.items) {
            const combinedQueries = this.intersectQueryWithQuerySet(subfeed.query, this.userFollowQueries);
            this.cacheContentItemsFromQuerySets(subfeed.contentItems, combinedQueries, this.userHideQueries);
        }
    }

    followingQueryShouldBePrevented(query) {
        return this.actionOnQueryWouldExceedEffectivePlan(query, 'follow');
    }

    async followQuery(query) {
        if(this.followingQueryShouldBePrevented(query)) {
            return false;
        } else {
            await super.followQuery(query);
            return true;
        }
    }

    generateNewUserFeed() {
        const nestedFeed = Feed.nestedFromQueriesAndContentItems(this.userFollowQueries, this.userHideQueries, this.userFeedContentItems);
        this.cacheNestedFeedContentItems(nestedFeed, this.userHideQueries);
        this.cacheNestedFeedContentItemsAsUserFeedIntersections(nestedFeed);
        this.userFeed = nestedFeed;
    }

    getAllQueryData() {
        const followQueryData = [];

        for(const query of [...this.userFollowQueries]) {
            followQueryData.push(query.toData());
        }

        const blockQueryData = [];

        for(const query of [...this.userHideQueries]) {
            blockQueryData.push(query.toData());
        }
        
        return {
            followQueries: followQueryData,
            blockQueries: blockQueryData
        };
    }

    async getBillingPortalUrl() {
        return await this.externalDataManager.getBillingPortalUrl();
    }

    getCachedContentItemsFromCombinedKey(combinedKey) {
        return this.querySetsToContentItemsCache[combinedKey];
    }

    async getCheckoutUrlFromStripePriceId(priceId) {
        return await this.externalDataManager.getCheckoutUrlFromStripePriceId(priceId);
    }

    getConceptFromId(conceptId) {
        const existingConcept = this.concepts[conceptId];

        if(existingConcept) {
            return existingConcept;
        } else {
            const newConcept = Concept.fromIdAndLabel(conceptId, '?');
            this.concepts[conceptId] = newConcept;
            return newConcept;
        }
    }

    async getContentItemsFromQueries(showQueries, hideQueries=new Set()) {
        const combinedQueriesKey = getShowAndHideQuerySetsKey(showQueries, hideQueries);
        const cachedContentItems = this.getCachedContentItemsFromCombinedKey(combinedQueriesKey);

        if(cachedContentItems) {
            return cachedContentItems;
        } else {
            const contentItems = await this.externalDataManager.getContentItemsFromQueries(showQueries, hideQueries);
            this.cacheContentItemsFromCombinedKey(contentItems, combinedQueriesKey);
            return contentItems;
        }
    }

    async getCustomSourceConceptFromUrl(url, label=null) {
        const finalUrl = await this.externalDataManager.getFinalThirdPartyFeedUrl(url);

        if(finalUrl) {
            const conceptId = CustomSourceConcept.getIdFromUrl(finalUrl);
            const existingConcept = this.concepts[conceptId];

            if(existingConcept) {
                return existingConcept;
            } else {
                const newConceptLabel = label ? label : finalUrl.toString();
                const newConcept = new CustomSourceConcept(finalUrl, newConceptLabel);
                this.concepts[newConcept.id] = newConcept;
                return newConcept;
            }
        } else {
            return null;
        }
    }
    
    async getCustomSourceQueryFromUrl(url) {
        const customSourceConcept = await this.getCustomSourceConceptFromUrl(url);

        if(customSourceConcept) {
            return this.getQueryFromConcepts(customSourceConcept);
        } else {
            return null;
        }
    }

    getEffectiveUserPlan() {
        if(this.userSubscriptionNeedsAction) {
            return FREE_USER;
        } else {
            return this.userPlan;
        }
    }

    async getFlatFeedFromQueries(showQueries, hideQueries=new Set()) {
        const nestedFeed = await this.getNestedFeedFromQueries(showQueries, hideQueries);
        return nestedFeed.flatten();
    }

    async getNestedFeedFromQueries(showQueries, hideQueries=new Set()) {
        const contentItems = await this.getContentItemsFromQueries(showQueries, hideQueries);
        const nestedFeed = Feed.nestedFromQueriesAndContentItems(showQueries, hideQueries, contentItems);
        this.cacheNestedFeedContentItems(nestedFeed, hideQueries);
        return nestedFeed;
    }

    getQueryCount() {
        return this.userFollowQueries.size + this.userHideQueries.size;
    }

    getQueryFromConceptAndTypeText(concept, typeText) {
        if(typeText === 'source') {
            return this.getQueryFromConcepts(concept);
        } else if(typeText === 'topic') {
            return this.getQueryFromConcepts(null, [concept]);
        } else /*if(typeText === 'author')*/ {
            return this.getQueryFromConcepts(null, [], [concept]);
        }
    }
    
    getQueryFromConcepts(source=null, topics=[], authors=[]) {
        const newQuery = new Query(source, topics, authors);
        const newQueryKey = newQuery.key();
        const oldQueryWithSameKey = this.queries[newQueryKey];

        if(oldQueryWithSameKey) {
            return oldQueryWithSameKey;
        } else {
            this.queries[newQueryKey] = newQuery;
            return newQuery;
        }
    }

    async getSearchSuggestionsFromQueryAndText(query, text) {
        return await this.externalDataManager.getSearchSuggestionsFromQueryAndText(query, text);
    }
    
    getUserEmailAddress() {
        return this.userEmailAddress;
    }

    getUserFeed() {
        return this.userFeed;
    }

    getUserFeedMode() {
        return this.userFeedMode;
    }

    getUserFollowQueries() {
        return this.userFollowQueries;
    }

    getUserHideQueries() {
        return this.userHideQueries;
    }

    getUserPlan() {
        return this.userPlan;
    }

    getWhetherUserSubscriptionNeedsAction() {
        return this.userSubscriptionNeedsAction;
    }

    async handleDataUpdate(functionName, arg) {
        this.ui.handleCoreUpdate();
        await this.externalDataManager[functionName](arg);

        if(this.isQueryActionName(functionName)) {
            this.updateAttributesFromExternalDataManager();
        }
    }

    async hideQuery(query) {
        if(this.hidingQueryShouldBePrevented(query)) {
            return false;
        } else {
            await super.hideQuery(query);
            return true;
        }
    }

    hidingQueryShouldBePrevented(query) {
        return this.actionOnQueryWouldExceedEffectivePlan(query, 'hide');
    }

    intersectQueries(queries) {
        let newSource;
        const newTopics = new Set();
        const newAuthors = new Set();
        
        // If any query has a source, use that. If there are conflicting sources, return null.
        for(const query of queries) {
            const currentSource = query.source;

            if(currentSource) {
                if(newSource && currentSource !== newSource) {
                    return null;
                } else {
                    newSource = currentSource;
                }
            }
        }

        // Collect all topics and authors.
        for(const query of queries) {
            for(const topic of query.topics) {
                newTopics.add(topic);
            }

            for(const author of query.authors) {
                newAuthors.add(author);
            }
        }

        if(newSource || newTopics.size > 0 || newAuthors.size > 0) {
            return this.getQueryFromConcepts(newSource, newTopics, newAuthors);
        } else {
            return null;
        }
    }

    intersectQueryWithQuerySet(singleQuery, querySet) {
        const intersectedQueries = new Set();

        for(const setQuery of [...querySet]) {
            const intersectedQuery = this.intersectQueries([singleQuery, setQuery]);

            if(intersectedQuery) {
                intersectedQueries.add(intersectedQuery);
            }
        }

        return intersectedQueries;
    }

    notifyExternalDataManagerHasUpdate() {
        this.updateAttributesFromExternalDataManager();
    }
    
    processConceptData(conceptsLabels, conceptsIndexes) {
        updateIdToConceptObjWithConceptsData(this.concepts, conceptsLabels, conceptsIndexes);
    }

    processQueriesDataAndGetQueriesSet(queriesData) {
        const queries = new Set();
        
        for(const queryData of queriesData) {
            queries.add(this.processQueryDataAndGetQuery(queryData));
        }

        return queries;
    }
    
    processQueryDataAndGetQuery(queryData) {
        let source;

        if(queryData.sourceId) {
            source = this.getConceptFromId(queryData.sourceId);
        } else {
            source = null;
        }

        const topics = [];

        if(queryData.topicIds) {
            for(const conceptId of queryData.topicIds) {
                topics.push(this.getConceptFromId(conceptId));
            }
        }

        const authors = [];

        if(queryData.authorIds) {
            for(const authorId of queryData.authorIds) {
                authors.push(this.getConceptFromId(authorId));
            }
        }

        return this.getQueryFromConcepts(source, topics, authors);
    }

    async refreshUserFeed() {
        this.resetQuerySetsToContentItemsCache();
        await this.externalDataManager.refreshUserFeed();
        this.updateAttributesFromExternalDataManager();
    }

    async requestConfirmationEmail(emailAddress, reason) {
        return await this.externalDataManager.requestConfirmationEmail(emailAddress, reason);
    }

    async requestPasswordChangeConfirmationEmail() {
        return await this.requestConfirmationEmail(null, CONFIRM_PASSWORD_CHANGE)
    }

    async requestSignInConfirmationEmail(emailAddress) {
        return await this.requestConfirmationEmail(emailAddress, CONFIRM_SIGN_IN);
    }

    async requestSignUpConfirmationEmail(emailAddress) {
        return await this.requestConfirmationEmail(emailAddress, CONFIRM_SIGN_UP);
    }

    resetQuerySetsToContentItemsCache() {
        this.querySetsToContentItemsCache = {};
    }

    separateQuerySetByContainmentOfCustomSource(queries) {
        const standardQueries = new Set();
        const customSourceQueries = new Set();

        for(const query of [...queries]) {
            if(query.hasCustomSource()) {
                customSourceQueries.add(query);
            } else {
                standardQueries.add(query);
            }
        }

        return [standardQueries, customSourceQueries];
    }

    setFieldsToDefaults() {
        super.setFieldsToDefaults();
        this.generateNewUserFeed();
    }
    
    async signOut() {
        this.reset();
        this.ui.handleCoreUpdate();
        await this.externalDataManager.signOut();
    }

    startedWithDefaults() {
        return this.externalDataManager.usedDefaults();
    }

    async startup() {
        this.ui.showLoading();

        await this.externalDataManager.read();

        this.updateAttributesFromExternalDataManager();
    }

    updateAttributesFromExternalDataManager() {
        this.updateAttributesFromDataManager(this.externalDataManager);
        this.generateNewUserFeed();
        this.ui.handleCoreUpdate();
    }

    userPlanIsExceeded() {
        return configExceedsPlan(this.userFollowQueries, this.userHideQueries, this.userPlan);
    }
}