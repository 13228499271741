import $ from 'jquery';
import { ZNDataManager } from "./DataManager";
import { ContentItem } from "./Feed";
import { buildRequestJsonFromQueries } from "./general";

const CONTENT_CACHE_ENDPOINT = process.env.REACT_APP_CONTENT_CACHE_ENDPOINT;

export class ContentCacheDataManager extends ZNDataManager {
    constructor(zn) {
        super(zn);
        this.queryKeyToContentItemsCache = {};
    }

    async getContentItemsFromQueries(showQueries, hideQueries) {
        const promises = [];
        
        for(const showQuery of showQueries) {
            promises.push(this.getResponseDataFromQueryAndHideQueries(showQuery, hideQueries));
        }

        const seenArticleIds = new Set();
        const uniqueArticlesData = [];

        for(const promise of promises) {
            const [articlesData, conceptToIdMap] = await promise;

            if(articlesData && conceptToIdMap) {
                this.zn.processConceptData(conceptToIdMap);

                for(const articleData of articlesData) {
                    const articleId = articleData.articleId;
                    
                    if(!seenArticleIds.has(articleId)) {
                        seenArticleIds.add(articleId);
                        uniqueArticlesData.push(articleData);
                    }
                }
            }
        }

        const uniqueContentItems = ContentItem.arrayFromDataArray(this.zn, uniqueArticlesData);
        return uniqueContentItems;
    }

    async getResponseDataFromQueryAndHideQueries(query, hideQueries) {
        const responseData = await $.get({
            url: CONTENT_CACHE_ENDPOINT,
            dataType: 'json',
            data: {
                data: buildRequestJsonFromQueries([query], hideQueries)
            }
        });
        const queryKeyToArticlesMap = responseData.articles ?? {};
        const conceptToIdMap = responseData.concepts ?? {};
        const queryKey = query.key();
        const articlesDataArr = queryKeyToArticlesMap[queryKey] ?? [];
        return [articlesDataArr, conceptToIdMap];
    }
}