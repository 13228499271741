import { ContentCacheDataManager } from "./ContentCacheDataManager";
import { ZNDataManager } from "./DataManager";
import { Feed, mergeContentItemsArrays } from "./Feed";
import { textToBool } from "./general";
import { LocalStorageDataManager } from "./LocalStorageDataManager";
import { RemoteDataManager } from "./RemoteDataManager";
import { ThirdPartyDataManager } from "./ThirdPartyDataManager";

export class ExternalDataManager extends ZNDataManager {
    constructor(zn, updateNotifier) {
        super(zn, updateNotifier);

        this.notifyRemoteHasUpdate = this.notifyRemoteHasUpdate.bind(this);

        this.localStorageDataManager = new LocalStorageDataManager(zn);
        this.remoteDataManager = new RemoteDataManager(zn, this.notifyRemoteHasUpdate);
        this.thirdPartyDataManager = new ThirdPartyDataManager();
        const useContentCache = textToBool(process.env.REACT_APP_USE_CONTENT_CACHE);
        this.indexedContentDataManager = useContentCache ? new ContentCacheDataManager(zn) : this.remoteDataManager;
    }

    async attemptEmailConfirmation(confirmationCode, emailAddress, setPassword, password, queryData) {
        const attempt = await this.remoteDataManager.attemptEmailConfirmation(confirmationCode,
            emailAddress, setPassword, password, queryData);

        if(attempt.wasSuccessful) {
            await this.handleSuccessfulSignIn();
        }

        return attempt;
    }

    async attemptPasswordChange(oldPassword, newPassword) {
        return await this.remoteDataManager.attemptPasswordChange(oldPassword, newPassword);
    }

    async attemptSignIn(emailAddress, password, keepExistingQueries) {
        const signInAttempt = await this.remoteDataManager.attemptSignIn(emailAddress, password, keepExistingQueries);

        if(signInAttempt.wasSuccessful) {
            await this.handleSuccessfulSignIn();
        }

        return signInAttempt;
    }

    async getAccountAndFeedData() {
        let accountAndFeedData;
        
        if(this.userIsSignedIn) {
            accountAndFeedData = await this.remoteDataManager.getAccountAndFeedData();
            const requestWasSuccessful = accountAndFeedData.success;

            if(requestWasSuccessful) {
                this.updateLocallyStoredData();
                
                return accountAndFeedData;
            } else {
                this.zn.reset();
            }
        } else {
            accountAndFeedData = await this.getUpdatedContentItemsOnly();
        }

        return accountAndFeedData;
    }

    async getBillingPortalUrl() {
        return await this.remoteDataManager.getBillingPortalUrl();
    }

    async getCheckoutUrlFromStripePriceId(priceId) {
        return await this.remoteDataManager.getCheckoutUrlFromStripePriceId(priceId);
    }

    async getContentItemsFromQueries(showQueries, hideQueries=new Set()) {
        const [standardQueries, customSourceQueries] = this.zn.separateQuerySetByContainmentOfCustomSource(showQueries);
        
        const standardQueriesContentItems = standardQueries.size > 0
            ? await this.indexedContentDataManager.getContentItemsFromQueries(standardQueries, hideQueries) : [];
        const customSourceQueryContentItems = customSourceQueries.size > 0
            ? await this.thirdPartyDataManager.getContentItemsFromQueries(customSourceQueries, hideQueries) : [];

        const allContentItems = standardQueriesContentItems.concat(customSourceQueryContentItems);
        allContentItems.sort(Feed.newestFirstComparator);
        return allContentItems;
    }

    async getFinalThirdPartyFeedUrl(url) {
        return await this.thirdPartyDataManager.getFinalFeedUrl(url);
    }

    async getSearchSuggestionsFromQueryAndText(query, text) {
        return await this.remoteDataManager.getSearchSuggestionsFromQueryAndText(query, text);
    }

    async getSignInAttemptData(emailAddress, password, queryData) {
        return await this.remoteDataManager.getSignInAttemptData(emailAddress, password, queryData);
    }

    async getUpdatedContentItemsOnly() {
        return await this.getContentItemsFromQueries(this.userFollowQueries, this.userHideQueries);
    }

    async handleDataUpdate(functionName, arg) {
        await this.localStorageDataManager[functionName](arg);
        
        if((functionName === 'follow' || functionName === 'unfollow') && arg.hasCustomSource())
        await this.thirdPartyDataManager[functionName](arg);

        if(this.userIsSignedIn) {
            await this.remoteDataManager[functionName](arg);
            this.userFeedContentItems = this.remoteDataManager.getUserFeedContentItems();
            await this.updateFeedContentItems();
        } else if(this.isQueryActionName(functionName)) {
            await this.updateContentItemsOnly();
        }
    }

    async handleSuccessfulSignIn() {
        this.updateAttributesFromRemoteDataManager();
        await this.updateFeedContentItems();
        this.updateLocalStorageDataManagerAttributesFromSelf();
        await this.localStorageDataManager.write();
    }

    mergeThirdPartyUserFeedContentItemsIntoSelfUserFeedContentItems() {
        this.userFeedContentItems = mergeContentItemsArrays(this.userFeedContentItems, this.thirdPartyDataManager.getUserFeedContentItems());
    }

    notifyRemoteHasUpdate() {
        this.updateAttributesFromRemoteDataManager();
        this.updateLocalStorageDataManagerAttributesFromSelf();
        this.updateNotifier();
    }

    async read() {
        const local = this.localStorageDataManager;
        const remote = this.remoteDataManager;

        local.read();

        const storedEmail = local.getUserEmailAddress();
        const isLocalAccount = storedEmail === null;
        
        if(isLocalAccount) {
            this.updateAttributesFromLocalStorageDataManager();
            await this.updateContentItemsOnly();
        } else {
            await remote.read();
            this.updateAttributesFromRemoteDataManager();
            this.userFeedContentItems = remote.getUserFeedContentItems();
            await this.updateFeedContentItems();
        }
    }

    readLocalOnly() {
        this.localStorageDataManager.read();
        this.updateAttributesFromLocalStorageDataManager();
    }

    async refreshUserFeed() {
        this.thirdPartyDataManager.resetCaches();
        await this.read();
        this.updateNotifier();
    }

    async requestConfirmationEmail(emailAddress, reason) {
        return await this.remoteDataManager.requestConfirmationEmail(emailAddress, reason);
    }

    async signOut() {
        this.reset();
        this.localStorageDataManager.reset();
        await this.localStorageDataManager.resetStoredData();
        await this.remoteDataManager.signOut();
    }

    updateAttributesFromLocalOrRemoteDataManager(dataManager) {
        super.updateAttributesFromDataManager(dataManager);
        this.userFeedMode = this.localStorageDataManager.getUserFeedMode();
        this.updateThirdPartyDataManagerQueriesFromSelf();
    }

    updateAttributesFromLocalStorageDataManager() {
        this.updateAttributesFromLocalOrRemoteDataManager(this.localStorageDataManager);
    }

    updateAttributesFromRemoteDataManager() {
        const currentUserFeedMode = this.userFeedMode;
        
        this.updateAttributesFromLocalOrRemoteDataManager(this.remoteDataManager);

        this.userFeedMode = currentUserFeedMode;
    }

    async updateContentItemsOnly() {
        this.userFeedContentItems = await this.getUpdatedContentItemsOnly();
    }

    updateDataManagerAttributesFromSelf(dataManager) {
        dataManager.updateAttributesFromDataManager(this);
    }

    async updateFeedContentItems() {
        const tpdmReadPromise = this.thirdPartyDataManager.read();

        if(!this.userFeedContentItems) {
            this.userFeedContentItems = await this.indexedContentDataManager.getContentItemsFromQueries(this.userFollowQueries, this.userHideQueries);
        }

        await tpdmReadPromise;
        this.mergeThirdPartyUserFeedContentItemsIntoSelfUserFeedContentItems();
    }

    updateLocallyStoredData() {
        this.localStorageDataManager.read();
    }

    updateLocalStorageDataManagerAttributesFromSelf() {
        this.updateDataManagerAttributesFromSelf(this.localStorageDataManager);
    }

    updateThirdPartyDataManagerFollowQueriesFromSelf() {
        this.updateThirdPartyDataManagerQueriesFromQueriesAndUpdateMethodName(this.userFollowQueries, 'setUserFollowQueries');
    }

    updateThirdPartyDataManagerHideQueriesFromSelf() {
        this.updateThirdPartyDataManagerQueriesFromQueriesAndUpdateMethodName(this.userHideQueries, 'setUserHideQueries');
    }

    updateThirdPartyDataManagerQueriesFromQueriesAndUpdateMethodName(selfQueries, updateMethodName) {
        const customSourceQueries = this.zn.separateQuerySetByContainmentOfCustomSource(selfQueries)[1];
        this.thirdPartyDataManager[updateMethodName](customSourceQueries);
    }
    
    updateThirdPartyDataManagerQueriesFromSelf() {
        this.updateThirdPartyDataManagerFollowQueriesFromSelf();
        this.updateThirdPartyDataManagerHideQueriesFromSelf();
    }

    usedDefaults() {
        return this.localStorageDataManager.usedDefaults;
    }
}