export const CHRONOLOGICAL_FEED_MODE = 0;
export const GROUPED_FEED_MODE = 1;

export const CONFIRM_SIGN_IN = 0;
export const CONFIRM_SIGN_UP = 1;
export const CONFIRM_PASSWORD_CHANGE = 2;

export const domParser = new DOMParser();

class Plan {
    static planIdToPlan = {};
    
    constructor(planId, name, sourceFollowCount, premiumFollowCount, hideCount, isSpecial=false, usdPrice=null, stripePriceId=null) {
        this.planId = planId;
        this.constructor.planIdToPlan[planId] = this;

        this.name = name;
        this.sourceFollowCount = sourceFollowCount;
        this.premiumFollowCount = premiumFollowCount;
        this.hideCount = hideCount;
        this.isSpecial = isSpecial;
        this.usdPrice = usdPrice;
        this.stripePriceId = stripePriceId;
        this.isSubscription = stripePriceId !== null;
    }
}

export const ADMIN_USER = new Plan(0, 'Admin', Infinity, Infinity, Infinity, true);
export const FREE_USER = new Plan(1, 'Basic', 150, 5, 1, false, 0);
export const LIFETIME_PREMIUM_USER = new Plan(2, 'Lifetime Premium Unlimited', 1000, 1000, 1000, true);
export const STANDARD_USER = new Plan(3, 'Standard', 500, 50, 10, false, 5, process.env.REACT_APP_STANDARD_ANNUAL_PRICE_ID);
export const PREMIUM_USER = new Plan(4, 'Premium', 1000, 100, 30, false, 10, process.env.REACT_APP_PREMIUM_ANNUAL_PRICE_ID);

export const PLAN_ID_TO_PLAN = Plan.planIdToPlan;

export function getKeyFromItemData(itemData) {
    let keyParts = [];

    if('topicId' in itemData) {
        keyParts.push('t' + itemData.topicId.toString());
    }

    if('sourceId' in itemData) {
        keyParts.push('s' + itemData.sourceId.toString());
    }

    return keyParts.join(';');
}

export class Concept {
    constructor(id, label) {
        this.id = id;
        this.label = label;
    }

    static fromIdAndLabel(id, providedLabel) {
        const url = Concept.urlFromId(id);

        if(url) {
            const label = providedLabel ?? url.toString();
            return new CustomSourceConcept(url, label);
        } else {
            return new Concept(id, providedLabel);
        }
    }

    static regexStrFromConceptIndexes(indexes) {
        const regexComponents = [];

        for(const index of indexes) {
            const indexRegexSubstr = Concept.regexSubstrFromConceptIndex(index);
            regexComponents.push('(' + indexRegexSubstr + ')');
        }

        return '\\b(' + regexComponents.join('|') + ')\\b';
    }

    static regexSubstrFromConceptIndex(index) {
        return [...index].join('(\\w|\\b)*');  // Insert (\w|\b)* between every character.
    }

    static urlFromId(id) {
        if(id.substring(0, 3) === 'cs:') {
            const url = getNormalizedUrlTextFromText(id.substring(3));
            return url;
        } else {
            return null;
        }
    }

    setIndexes(indexes) {
        this.regex = new RegExp(Concept.regexStrFromConceptIndexes(indexes), 'i');
    }

    textMatches(text) {
        return this.regex ? this.regex.test(text) : false;
    }
}

class CustomConcept extends Concept {}

export class CustomSourceConcept extends CustomConcept {
    constructor(url, label) {
        const id = CustomSourceConcept.getIdFromUrl(url);
        super(id, label);

        this.url = url;
    }

    static getIdFromUrl(url) {
        return 'cs:' + url.toString();
    }

    setId() {
        this.id = CustomSourceConcept.getIdFromUrl(this.url);
    }

    setUrl(url) {
        this.url = url;
        this.setId();
    }
}

export function updateIdToConceptObjWithConceptsData(conceptsMapObj, conceptsLabels, conceptsIndexes) {
    for(const [conceptId, conceptLabel] of Object.entries(conceptsLabels)) {
        let concept = conceptsMapObj[conceptId];

        if(concept) {
            if(conceptLabel) {
                concept.label = conceptLabel;
            }
        } else {
            concept = Concept.fromIdAndLabel(conceptId, conceptLabel);
            conceptsMapObj[conceptId] = concept;
        }
    }

    if(conceptsIndexes) {
        for(const [conceptId, conceptIndexes] of Object.entries(conceptsIndexes)) {
            const concept = conceptsMapObj[conceptId];

            if(concept) {
                concept.setIndexes(conceptIndexes);
            }
        }
    }
}

export class Query {
    constructor(source=null, topics=[], authors=[]) {
        this.source = source;
        this.topics = [...topics].sort(idComparator);
        this.authors = [...authors].sort(idComparator);

        this.key = this.key.bind(this);
    }

    hasAnAuthor() {
        return this.authors.length > 0;
    }

    hasATopic() {
        return this.topics.length > 0;
    }

    hasAnAuthorOrTopic() {
        return this.hasAnAuthor() || this.hasATopic();
    }

    hasASource() {
        return Boolean(this.source);
    }

    hasCustomSource() {
        return this.hasASource() && Boolean(this.source.url);
    }

    isPremium() {
        return this.hasAnAuthorOrTopic();
    }

    isSourceOnly() {
        return !this.hasAnAuthorOrTopic();
    }

    key() {
        let keyParts = [];

        if(this.source) {
            keyParts.push('s' + this.source.id.toString());
        }

        for(const topic of this.topics) {
            keyParts.push('t' + topic.id.toString());
        }

        for(const author of this.authors) {
            keyParts.push('a' + author.id.toString());
        }

        return keyParts.join('');
    }

    toData() {
        let data = {};

        if(this.source) {
            data.sourceId = this.source.id;
        }

        if(this.topics.length > 0) {
            const topicIds = [];
            
            for(const topic of this.topics) {
                topicIds.push(topic.id);
            }

            data.topicIds = topicIds;
        }

        if(this.authors.length > 0) {
            const authorIds = [];

            for(const author of this.authors) {
                authorIds.push(author.id);
            }

            data.authorIds = authorIds;
        }

        return data;
    }
}

export function queriesToIdToQueryMapObj(queries) {
    const mapObj = {};
    
    for(const query of [...queries]) {
        mapObj[query.key()] = query;
    }

    return mapObj;
}

export function conceptLabelComparator(a, b) {
    const lowerALabel = a.label.toLowerCase();
    const lowerBLabel = b.label.toLowerCase();
    
    return lowerALabel.localeCompare(lowerBLabel);
}

export function nullishLastMaybeConceptLabelComparator(a, b) {
    if(a) {
        if(b) {
            return conceptLabelComparator(a, b);
        } else {
            return 1;
        }
    } else if(b) {
        return -1;
    } else {
        return 0;
    }
}

export function idComparator(a, b) {
    const aId = a.id;
    const bId = b.id;
    
    if(aId > bId) {
        return 1;
    } else if(aId < bId) {
        return -1;
    } else {
        return 0;
    }
}

class SequenceTreeNode {
    constructor() {
        this.children = {};
        this.shortestPathItem = undefined;
        this.shortestPathLen = -1;
    }
}

export class SequenceTree {
    constructor(emptyItem) {
        const root = new SequenceTreeNode();
        root.shortestPathItem = emptyItem;
        root.shortestPathLen = 0;
        this.root = root;

        this.addSequence = this.addSequence.bind(this);
    }

    addSequence(seq, item) {
        let currentNode = this.root;
        let lastNode, currentNodeShortestPathLen;

        const seqLen = seq.length;
        let remainingLen = seqLen;
        
        // Loop through each item of the sequence, following existing nodes and adding new ones as necessary.
        for(let seqPos=0; seqPos<seqLen; seqPos++) {
            // Get the current item and node.
            const currentItem = seq[seqPos];
            remainingLen--;
            
            lastNode = currentNode;
            const lastNodeChildren = lastNode.children;
            currentNode = lastNodeChildren[currentItem];

            // If a node does not yet exist at the current location in the path, create one and make it a child of the last node.
            if(!currentNode) {
                currentNode = new SequenceTreeNode();
                lastNodeChildren[currentItem] = currentNode;
            }

            // If this path is the shortest or the only path this node has been part of so far, update it to show this item as the shortest path item.
            currentNodeShortestPathLen = currentNode.shortestPathLen;
            
            if(currentNodeShortestPathLen === -1 || remainingLen < currentNodeShortestPathLen) {
                currentNode.shortestPathItem = item;
                currentNode.shortestPathLen = remainingLen;
            }
        }
    }

    hasExactMatch(seq) {
        let currentNode = this.root;

        const seqLen = seq.length;
        
        // If a node does not exist for any location in the path, then there is no exact match.
        for(let seqPos=0; seqPos<seqLen; seqPos++) {
            const currentChar = seq[seqPos];
            currentNode = currentNode.children[currentChar];

            if(!currentNode) {
                return false;
            }
        }

        // Even if nodes exist for every location in the path, there is only an exact match if the final node is at the end of its shortest path.
        return currentNode.shortestPathLen === 0;
    }

    getClosestMatchingSequenceItem(seq) {
        let currentNode = this.root;
        let closestLeftMatchItem = currentNode.shortestPathItem;
        let lastNode;

        const seqLen = seq.length;
        
        // Go as deep into the tree as possible following matching nodes.
        for(let seqPos=0; seqPos<seqLen; seqPos++) {
            const currentChar = seq[seqPos];
            lastNode = currentNode;
            currentNode = lastNode.children[currentChar];

            if(currentNode) {
                // While looping, keep track of the item of the deepest node encountered that has an exact match item.
                if(currentNode.shortestPathLen === 0) {
                    closestLeftMatchItem = currentNode.shortestPathItem;
                }
            } else {
                break;
            }
        }

        if(currentNode) {
            // If nodes existed along the whole path, return the final node's shortest path item.
            return currentNode.shortestPathItem;
        } else {
            // If no complete path exists, return the item of the deepest node encountered that had an exact match item.
            return closestLeftMatchItem;
        }
    }
}

export function queriesDataObjectsFromQueries(queries) {
    const queriesDataObjects = [];

    for(const query of [...queries]) {
        queriesDataObjects.push(query.toData());
    }

    return queriesDataObjects;
}

function getQuerySetKey(queries) {
    const queryKeys = [];

    for(const query of [...queries]) {
        queryKeys.push(query.key());
    }

    queryKeys.sort();

    const querySetKey = queryKeys.join('|');
    return querySetKey;
}

export function getShowAndHideQuerySetsKey(showQueries, hideQueries) {
    const showQueriesKey = getQuerySetKey(showQueries);
    const hideQueriesKey = getQuerySetKey(hideQueries);
    const combinedKey = 's(' + showQueriesKey + ');h(' + hideQueriesKey + ')';
    return combinedKey;
}

export function getNormalizedUrlTextFromText(text, baseUrl) {
    let url;
    
    try {
        url = new URL(text, baseUrl);
    } catch(_) {
        return null;
    }

    normalizeUrlObj(url);
    const normalizedUrlText = removeTrailingSlashesFromText(url.href);
    return normalizedUrlText;
}

export function filterOutContentItemsByQueries(queries, contentItems) {
    const remaining = [];

    for(const contentItem of contentItems) {
        let matches = false;

        for(const query of queries) {
            if(contentItem.matchesQuery(query)) {
                matches = true;
                break;
            }
        }

        if(!matches) {
            remaining.push(contentItem);
        }
    }

    return remaining;
}

function normalizeUrlObj(url) {
    url.protocol = 'https:';
}

function removeTrailingSlashesFromText(text) {
    while(text[text.length - 1] === '/') {
        text = text.slice(0, -1);
    }

    return text;
}

export function countSourceOnlyQueries(queries) {
    let count = 0;

    for(const query of [...queries]) {
        if(query.isSourceOnly()) {
            count++;
        }
    }

    return count;
}

export function statusCodeMeansSuccess(statusCode) {
	return 200 <= statusCode && statusCode < 300;
}

function buildRequestObjectFromQueries(showQueries, hideQueries) {
    const requestDataObject = {
        followQueries: queriesDataObjectsFromQueries(showQueries)
    };

    if(hideQueries.size > 0) {
        requestDataObject.blockQueries = queriesDataObjectsFromQueries(hideQueries);
    }

    return requestDataObject;
}

export function buildRequestJsonFromQueries(showQueries, hideQueries) {
    return JSON.stringify(buildRequestObjectFromQueries(showQueries, hideQueries));
}

export function textToBool(text) {
    return JSON.parse(text);
}

export function reverseString(str) {
    return str.split('').reverse().join('');
}

// This is to make email addresses harder for spam crawlers to find.
export function genAddr(backwardName) {
    const eParts = [backwardName, 'ymetaz', 'moc', 'otliam'];
    const decodedEParts = eParts.map(reverseString);
    const a = decodedEParts[0] + '@' + decodedEParts[1] + '.' + decodedEParts[2];
    const mHref = decodedEParts[3] + ':' + a;
    return <a href={ mHref }>{ a }</a>;
}

export function isFullscreen() {
    return window.innerHeight > window.screen.height - 50;
}

export function getUsageData(followQueries, hideQueries) {
    const followQueriesArr = [...followQueries];
    const hideQueriesArr = [...hideQueries];
    const numFollowedSourceQueries = countSourceOnlyQueries(followQueriesArr);
    const numFollowedPremiumQueries = followQueriesArr.length - numFollowedSourceQueries;
    const numHiddenQueries = hideQueriesArr.length;
    return [numFollowedSourceQueries, numFollowedPremiumQueries, numHiddenQueries];
}

export function configExceedsPlan(followQueries, hideQueries, plan) {
    const [numFollowedSourceQueries, numFollowedPremiumQueries, numHiddenQueries] = getUsageData(followQueries, hideQueries);
    return numFollowedSourceQueries > plan.sourceFollowCount
        || numFollowedPremiumQueries > plan.premiumFollowCount
        || numHiddenQueries > plan.hideCount;
}