import { Usage } from './Usage';

function TooManyQueriesPage({ui}) {
    function handleUpgradeClick() {
        ui.showPlans();
        ui.handleNavigationEvent();
    }

    return (
        <div className="info-page">
            <div className="info-section">
                <p className="info-title">
                    Too Many Searches!
                </p>
                <p className="info-text">
                    Your Crossfeed currently has more searches than are available under your current plan. Please remove 
                    some or <span className='fake-link' onClick={handleUpgradeClick}>upgrade your plan</span>.
                </p>
                <br/>
                <p className="info-title">
                    Your Current Usage
                </p>
                <p className="info-text">
                    <Usage ui={ui}/>
                </p>
            </div>
        </div>
    )
}

export default TooManyQueriesPage;