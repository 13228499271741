import { filterOutContentItemsByQueries } from "./general";

class FeedEntry {
    static newestFirstComparator(entry0, entry1) {
        return entry1.getSortTime() - entry0.getSortTime();
    }
}

export class ContentItem extends FeedEntry {
    constructor(url, title, imageUrl, source, time, topics, authors) {
        super();

        this.url = url;
        this.title = title;
        this.imageUrl = imageUrl;
        this.source = source;
        this.time = time;
        this.topics = new Set(topics);
        this.authors = new Set(authors);
    }

    getSortTime() {
        return this.time;
    }

    matchesQuerySource(query) {
        const querySource = query.source;
        return !querySource || querySource === this.source;
    }

    matchesQuerySet(query, setName) {
        const contentItemSet = this[setName];
        
        for(const queryItem of query[setName]) {
            if(!contentItemSet.has(queryItem)) {
                return false;
            }
        }

        return true;
    }
    
    matchesQueryTopics(query) {
        return this.matchesQuerySet(query, 'topics');
    }

    matchesQueryAuthors(query) {
        return this.matchesQuerySet(query, 'authors');
    }
    
    matchesQuery(query) {
        return this.matchesQuerySource(query) && this.matchesQueryTopics(query) && this.matchesQueryAuthors(query);
    }

    static arrayFromDataArray(zn, dataArray) {
        const contentItems = [];

        for(const contentItemData of dataArray) {
            contentItems.push(ContentItem.fromData(zn, contentItemData));
        }

        return contentItems;
    }
    
    static fromData(zn, data) {
        const source = zn.concepts[data.sourceId];

        const topics = [];

        for(const topicId of data.topicIds) {
            topics.push(zn.concepts[topicId]);
        }

        const authors = [];

        for(const authorId of data.authorIds) {
            authors.push(zn.concepts[authorId]);
        }

        return new ContentItem(data.url, data.title, data.imageUrl, source, data.time, topics, authors);
    }
}

export class CustomSourceContentItem extends ContentItem {
    matchesQueryTopics(query) {
        for(const topic of query.topics) {
            if(!topic.textMatches(this.title)) {
                return false;
            }
        }

        return true;
    }
}

export class Feed extends FeedEntry {
    constructor(items) {
        super();
        
        this.items = items;
        this.contentItems = items;
    }

    getSortTime() {
        if(this.items.length > 0) {
            return this.items[0].getSortTime();
        } else {
            return -1;
        }
    }
    
    static filteredFromQueryAndContentItems(query, contentItems) {
        const items = [];

        for(const contentItem of contentItems) {
            if(contentItem.matchesQuery(query)) {
                items.push(contentItem);
            }
        }

        return new QueryFeed(query, items);
    }

    static nestedFromQueriesAndContentItems(showQueries, hideQueries, contentItems) {
        const notHiddenContentItems = filterOutContentItemsByQueries(hideQueries, contentItems);
        const topLevelItems = [];
        const finalContentItemsSet = new Set();
        
        for(const query of showQueries) {
            const queryFeed = Feed.filteredFromQueryAndContentItems(query, notHiddenContentItems);

            if(queryFeed.items.length > 0) {
                topLevelItems.push(queryFeed);

                for(const feedItem of queryFeed.items) {
                    finalContentItemsSet.add(feedItem);
                }
            }
        }

        topLevelItems.sort(Feed.newestFirstComparator);
        const finalContentItems = [...finalContentItemsSet].sort(Feed.newestFirstComparator);

        return new NestedFeed(topLevelItems, finalContentItems);
    }

    static flatFromQueriesAndContentItems(queries, contentItems) {
        return new Feed(contentItems);
    }
}

class ContentFeed extends Feed {}

class QueryFeed extends ContentFeed {
    constructor(query, items) {
        super(items);

        this.query = query;
    }
}

class NestedFeed extends ContentFeed {
    constructor(childItems, allContentItems) {
        super(childItems);

        this.contentItems = allContentItems;
    }

    flatten() {
        return new Feed(this.contentItems);
    }
}

export function mergeContentItemsArrays(...arrs) {
    const merged = arrs.flat();
    merged.sort(FeedEntry.newestFirstComparator);
    return merged;
}