import React from 'react';

import { CHRONOLOGICAL_FEED_MODE, FREE_USER, GROUPED_FEED_MODE } from './general.js';
import FollowBlockContext from './FollowBlockContext.js';
import { PhraseQueryLabel } from './Labels.js';
import ArticleFeed from './ArticleFeed.js';
import TooManyQueriesPage from './TooManyQueries.js';
import PaymentsNeedAction from './PaymentsNeedAction.js';
import { trackMyFeedChange } from './CFAnalytics.js';
import ZatemyLogo from './ZatemyLogo.js';

class Button extends React.Component {
    render() {
        if (this.props.isUp) {
            return (
                <button id={this.props.id} className="info-bar-button info-bar-button-up" type="button" onClick={this.props.onPress}>
                    {this.props.upText}
                </button>
            );
        } else {
            return (
                <button id={this.props.id} className="info-bar-button info-bar-button-down" type="button" onClick={this.props.onUnpress}>
                    {this.props.downText}
                </button>
            );
        }
    }
}

class InfoBar extends React.Component {
    constructor(props) {
        super(props);

        this.follow = this.follow.bind(this);
        this.unfollow = this.unfollow.bind(this);
        this.hide = this.hide.bind(this);
        this.unhide = this.unhide.bind(this);
        this.toggleChronFeedMode = this.toggleChronFeedMode.bind(this);
    }

    async follow() {
        await this.props.ui.followQuery(this.props.feedDisplay.query);
        this.trackFeedChange('follow');
    }

    async unfollow() {
        await this.props.ui.unfollowQuery(this.props.feedDisplay.query);
        this.trackFeedChange('unfollow');
    }

    async hide() {
        await this.props.ui.hideQuery(this.props.feedDisplay.query);
        this.trackFeedChange('hide');
    }

    async unhide() {
        await this.props.ui.unhideQuery(this.props.feedDisplay.query);
        this.trackFeedChange('unhide');
    }

    toggleChronFeedMode() {
        let ui = this.props.ui;
        let newMode;

        if (ui.state.feedMode === CHRONOLOGICAL_FEED_MODE) {
            newMode = GROUPED_FEED_MODE;
        } else {
            newMode = CHRONOLOGICAL_FEED_MODE;
        }

        ui.setFeedMode(newMode);
    }

    trackFeedChange(actionName) {
        trackMyFeedChange(actionName, this.props.feedDisplay.query);
    }

    render() {
        const feedDisplay = this.props.feedDisplay;
        const query = feedDisplay.query;
        const isSearch = feedDisplay.isSearch;
        const isUserFeed = !isSearch;
        
        let right = [];

        if(query) {
            right.push(
                <Button id="follow-button" isUp={!this.props.followed} upText="Follow" downText="Unfollow"
                    onPress={this.follow} onUnpress={this.unfollow} key="follow" />,
                <Button isUp={!this.props.blocked} upText="Hide" downText="Unhide"
                    onPress={this.hide} onUnpress={this.unhide} key="block" />
            );
        } else {
            let chronToggleClassName = 'info-bar-tool-button';

            if (this.props.ui.state.feedMode === CHRONOLOGICAL_FEED_MODE) {
                chronToggleClassName += ' info-bar-tool-button-active';
            }

            right.push(
                <div className={chronToggleClassName} onClick={this.toggleChronFeedMode} key="chronMode">
                    grid_view
                </div>,
                <div className="info-bar-tool-button" onClick={this.props.runRefresh} key="refresh">
                    refresh
                </div>
            );
        }

        return (
            <div className="info-bar">
                <div className="info-bar-left" />
                <div className="info-bar-center">
                    <div className="info-bar-center-left">
                        <PhraseQueryLabel query={ query } fromFeed={ isUserFeed } fromSearch={ isSearch } contentPrefix/>
                    </div>
                    <div className="info-bar-center-right">
                        {right}
                    </div>
                </div>
                <div className="info-bar-right"></div>
            </div>
        );
    }
}

class SpecificFeed extends React.Component {
    constructor(props) {
        super(props);

        this.handleConceptClick = this.handleConceptClick.bind(this);
        this.handleLearnMoreClick = this.handleLearnMoreClick.bind(this);
        this.handleTouClick = this.handleTouClick.bind(this);
        this.handlePpClick = this.handlePpClick.bind(this);
    }

    handleConceptClick(query) {
        const isSearch = this.props.feedDisplay.isSearch;

        if (isSearch) {
            this.props.ui.showSearchFeedFromQuery(query);
        } else {
            this.props.ui.showFilteredUserFeedFromQuery(query);
        }
    }

    handleLearnMoreClick() {
        this.props.ui.showAboutPage();
        this.props.ui.handleNavigationEvent();
    }

    handlePpClick() {
        this.props.ui.showPrivacyPolicyPage();
        this.props.ui.handleNavigationEvent();
    }

    handleTouClick() {
        this.props.ui.showTermsPage();
        this.props.ui.handleNavigationEvent();
    }

    render() {
        const feedDisplay = this.props.feedDisplay;
        const feed = feedDisplay.feed;
        const isUserFeed = !feedDisplay.isSearch;
        const subscriptionNeedsAction = this.props.subscriptionNeedsAction;
        const query = feedDisplay.query;

        let followStatus, blockStatus;

        const followQueries = this.props.followQueries;
        const blockQueries = this.props.blockQueries;

        if (query) {
            followStatus = followQueries.has(query);
            blockStatus = blockQueries.has(query);
        }

        let entries;

        if(isUserFeed && feedDisplay.showAsFlat) {
            entries = feed.contentItems;
        } else {
            entries = feed.items;
        }

        let middleContent, middleMessage;

        if(isUserFeed && subscriptionNeedsAction && this.props.ui.zn.userIsExceedingPlan(FREE_USER)) {
            middleContent = <PaymentsNeedAction/>;
        } else if(isUserFeed && this.props.ui.zn.userPlanIsExceeded()) {
            middleContent = <TooManyQueriesPage ui={this.props.ui} />;
        } else {
            if(this.props.shouldShowWelcomeBanner) {
                middleMessage = (
                    <div className='middle-content-banner'>
                        <div className='welcome-close-button' onClick={ this.props.ui.hideWelcomeBanner }>
                            close
                        </div>
                        <div className="help-title text-bold">
                            Welcome to <ZatemyLogo/> Beta!
                        </div>
                        <p className="info-text help-entry">
                            <span className="text-bold">
                                Crossfeed saves you time by curating content you care about.
                            </span>
                            &nbsp;
                            <span className="text-subwhite">
                                <span className='fake-link' onClick={ this.handleLearnMoreClick }>Learn more</span>.
                            </span>
                        </p>
                        <p className="info-text info-text-small">
                            <span className="text-subwhite">
                                By continuing to use Crossfeed, you acknowledge that you have read and agreed to 
                                its <span className='fake-link' onClick={ this.handleTouClick }>Terms of Use</span> and
                                &nbsp;<span className='fake-link' onClick={ this.handlePpClick }>Privacy Policy</span>.
                            </span>
                        </p>
                    </div>
                );
            }
            
            middleContent = (
                <div className={ "middle-content-padding" + (middleMessage ? " middle-content-padding-after-message" : "")}>
                    <ArticleFeed ui={ this.props.ui } entries={ entries } handleNavigationEvent={ this.props.handleNavigationEvent }
                        conceptClickAction={ this.handleConceptClick } />
                </div>
            );
        }

        return (
            <div className="specific-feed">
                <InfoBar ui={ this.props.ui } feedDisplay={ feedDisplay } followed={ followStatus } blocked={ blockStatus } runRefresh={ this.props.runRefresh } />
                { middleMessage }
                { middleContent }
            </div>
        );
    }
}

SpecificFeed.contextType = FollowBlockContext;

export default SpecificFeed;