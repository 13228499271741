import React from 'react';

class InfinitelyScrollable extends React.Component {
    constructor(props) {
        super(props);

        this.itemsDivRef = React.createRef();
        this.endRef = React.createRef();

        this.observerCallback = this.observerCallback.bind(this);
    }

    componentDidMount() {
        let root;

        if(this.props.horizontal) {
            root = this.itemsDivRef.current;
        }

        this.observer = new IntersectionObserver(this.observerCallback, {
            root: root,
            rootMargin: this.props.endMargin
        });
        this.observer.observe(this.endRef.current);
    }

    observerCallback(entries, observer) {
        if(entries[0].isIntersecting && this.props.moreItemsAvailable) {
            this.props.endApproachingCallback();
        }
    }

    render() {
        let wrapperClassName = this.props.horizontal ? 'infinitely-scrollable-horizontal-wrapper' : 'infinitely-scrollable-vertical-wrapper';
        
        return (
            <div className={ wrapperClassName } ref={ this.itemsDivRef }>
                <div className={ this.props.className }>
                    { this.props.items }
                    <div className="grid-end-item" key="endItem" ref={ this.endRef }/>
                </div>
            </div>
        );
    }
}

export default InfinitelyScrollable;
