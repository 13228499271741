import React from 'react';

import { PhraseQueryLabel } from './Labels';

class SearchResult extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.props.loadFeed(this.props.query);
    }

    render() {
        return (
            <div className="search-result" onClick={ this.onClick }>
                <PhraseQueryLabel query={ this.props.query }/>
            </div>
        );
    }
}

class SearchResults extends React.Component {
    render() {
        let searchResults = this.props.searchResults;
        let display;
        
        if(searchResults.length > 0) {
            let results = [];

            for(const query of searchResults) {
                results.push(<SearchResult query={ query } loadFeed={ this.props.loadFeed } key={ query.key() }/>);
            }

            display = (
                <div className="search-results">
                    { results }
                </div>
            );
        } else {
            display = (
                <div className="no-search-results">
                    No results found. Make sure your search terms are spelled correctly or try a different search.
                </div>
            );
        }

        return (
            <div className="middle-content-padding">
                { display }
            </div>
        );
    }
}

export default SearchResults;