import React from 'react';
import { genAddr } from './general';

export default class ContactPage extends React.Component {
    render() {
        return (
            <div className="info-page">
                <div className="info-section">
                    <div className="help-title text-bold">
                        Contact
                    </div>
                    <p className="info-centered info-text">
                        Got a question or suggestion? Email me at { genAddr('nosloc') }.
                    </p>
                </div>
            </div>
        )
    }
}