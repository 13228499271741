import React from 'react';

class Alert extends React.Component {
    constructor(props) {
        super(props);

        this.handlePrimaryButtonClick = this.handlePrimaryButtonClick.bind(this);
        this.handleHideAlertButtonClick = this.handleHideAlertButtonClick.bind(this);
    }
    
    handlePrimaryButtonClick() {
        this.props.alertInfo.actionButtonFunc();
        this.props.handleHideAlert();
    }

    handleHideAlertButtonClick() {
        this.props.handleHideAlert();
    }
    
    render() {
        const alertInfo = this.props.alertInfo;

        if(alertInfo) {
            return (
                <div className="alert-view">
                    <div className="alert-box">
                        <div className="info-section">
                            <div className="info-title">
                                { alertInfo.title }
                            </div>
                            <p className="info-text">
                                { alertInfo.message }
                            </p>
                            <div className="alert-buttons">
                                <button className="alert-button alert-button-primary" onClick={ this.handlePrimaryButtonClick }>
                                    { alertInfo.actionButtonText }
                                </button>
                                <button className="alert-button alert-button-secondary" onClick={ this.handleHideAlertButtonClick }>
                                    Not right now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default Alert;