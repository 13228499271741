import React from 'react';

import { trackManagePlanSelect, trackPlanSelect } from './CFAnalytics';
import { FREE_USER, STANDARD_USER, PREMIUM_USER } from './general';
import { Usage } from './Usage';

function SearchWord({quantity}) {
    return <>{ quantity === 1 ? 'search' : 'searches' }</>;
}

class PlanCard extends React.Component {
    constructor(props) {
        super(props);

        this.handleCheckoutClick = this.handleCheckoutClick.bind(this);
    }

    handleCheckoutClick() {
        const plan = this.props.plan;
        const startCheckout = () => this.props.startCheckout(plan.stripePriceId);
        trackPlanSelect(plan, startCheckout);
    }

    render() {
        const plan = this.props.plan;
        const currentPlan = this.props.currentPlan;

        let actionDiv;

        if (plan === currentPlan) {
            actionDiv = (
                <div className="plan-bottom-text">
                    Current plan
                </div>
            );
        } else if (currentPlan === FREE_USER) {
            if (this.props.isSignedIn) {
                actionDiv = (
                    <button className="plan-button plan-button-primary" onClick={this.handleCheckoutClick}>
                        Upgrade
                    </button>
                );
            } else {
                actionDiv = (
                    <div className="plan-bottom-text">
                        Sign in to upgrade
                    </div>
                );
            }
        }

        return (
            <div className="plan">
                <div className="info-section plan-section">
                    <div className="plan-title">
                        {plan.name}
                    </div>
                    <div className="plan-price">
                        <div className="info-text">
                            $
                        </div>
                        <div className="info-text plan-price-number">
                            {plan.usdPrice.toString()}
                        </div>
                        <div className="info-text">
                            /mo
                        </div>
                    </div>
                    <div className="info-text plan-text">
                        <p>
                            Follow {plan.sourceFollowCount.toString()} source <SearchWord quantity={plan.sourceFollowCount}/>
                        </p>
                        <p className="info-text plan-text">
                            Follow {plan.premiumFollowCount.toString()} premium <SearchWord quantity={plan.premiumFollowCount}/>
                        </p>
                        <p>
                            Hide {plan.hideCount.toString()} <SearchWord quantity={plan.hideCount}/>
                        </p>
                    </div>
                    {actionDiv}
                </div>
            </div>
        );
    }
}

class Plans extends React.Component {
    constructor(props) {
        super(props);

        this.handleManageSubscriptionClick = this.handleManageSubscriptionClick.bind(this);
    }

    handleManageSubscriptionClick() {
        const currentPlan = this.props.accountType;
        trackManagePlanSelect(currentPlan, this.props.visitBillingPortal);
    }

    render() {
        const isSignedIn = this.props.accountData !== null;
        const currentPlan = this.props.accountType;

        let currentPlanItem;

        if (currentPlan.isSubscription) {
            currentPlanItem = (
                <button className="plan-button plan-button-primary" onClick={this.handleManageSubscriptionClick}>
                    Manage subscription
                </button>
            );
        } else if (currentPlan.isSpecial) {
            currentPlanItem = (
                <p>
                    Your current plan ({currentPlan.name}) is a specialized plan that can only be modified by Zatemy.
                </p>
            );
        }

        return (
            <div className="info-page">
                <div className="info-section">
                    <div className="plans-title">
                        Plans & Pricing
                    </div>
                    <div className="info-centered info-text">
                        <p>
                            Plan changes happen through a billing portal hosted by Stripe. To learn how Stripe
                            protects your sensitive data,
                            visit <a href="https://stripe.com/docs/security/stripe" target="_blank" rel="noopener noreferrer">their documentation</a>.
                        </p>
                        <p>
                            A <span className="text-italics">source search</span> is any search with only a source (no topics or authors).
                            <br />
                            A <span className="text-italics">premium search</span> is any search with at least 1 topic or author.
                        </p>
                        <p>
                            <span className='info-title'>Your Current Usage</span>
                            <br/>
                            <Usage ui={this.props.ui}/>
                        </p>
                        {currentPlanItem}
                    </div>
                    <div className="plans">
                        <PlanCard isSignedIn={isSignedIn} plan={FREE_USER} currentPlan={currentPlan} startCheckout={this.props.startCheckout} />
                        <PlanCard isSignedIn={isSignedIn} plan={STANDARD_USER} currentPlan={currentPlan} startCheckout={this.props.startCheckout} />
                        <PlanCard isSignedIn={isSignedIn} plan={PREMIUM_USER} currentPlan={currentPlan} startCheckout={this.props.startCheckout} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Plans;