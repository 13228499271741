import React from 'react';

class Form extends React.Component {
    constructor(props) {
        super(props);

        let initialValues = {};

        for(let i=0; i<this.props.fields.length; i++) {
            let fieldData = this.props.fields[i];

            initialValues[fieldData.id] = '';
        }

        this.state = {
            values: initialValues
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(event) {
        let currentValueData = {};
        const isCheckbox = event.target.type === 'checkbox';
        currentValueData[event.target.id] = isCheckbox ? event.target.checked : event.target.value;

        this.setState({
            values: Object.assign({}, this.state.values, currentValueData)
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.props.onSubmit(this.state.values);
    }
    
    render() {
        let elements = [];
        
        for(let i=0; i<this.props.fields.length; i++) {
            let fieldData = this.props.fields[i];
            const fieldId = fieldData.id;
            const fieldLabel = fieldData.label;
            const fieldType = fieldData.type;
            const fieldValue = this.state.values[fieldId];
            const isCheckbox = fieldType === 'checkbox';
            elements.push(
                <div className={ 'form-field form-field-' + fieldType } key={ fieldId }>
                    { !isCheckbox
                        ? <span className="form-field-label">{ fieldLabel }</span>
                        : null
                    }
                    <input className={ 'form-input form-input-' + fieldType } type={ fieldType } id={ fieldId }
                        value={ isCheckbox ? undefined : fieldValue } checked={ isCheckbox ? fieldValue : undefined }
                        autoComplete={ fieldData.autoComplete } onChange={ this.handleChange }
                    />
                    { isCheckbox
                        ? <label className='form-field-label' htmlFor={ fieldId }>{ fieldLabel }</label>
                        : null
                    }
                </div>
            );
        }

        return (
            <form className="form" onSubmit={ this.handleSubmit }>
                { elements }
                <div className="form-bottom-div">
                    <button type="submit" className="form-submit">
                        { this.props.submitText }
                    </button>
                    <div className="form-message">
                        { this.props.message }
                    </div>
                </div>
            </form>
        );
    }
}

export default Form;