import $ from 'jquery';

import { ZNDataManager } from './DataManager';
import { ContentItem } from './Feed';
import { buildRequestJsonFromQueries, CONFIRM_PASSWORD_CHANGE, CONFIRM_SIGN_IN, CONFIRM_SIGN_UP, PLAN_ID_TO_PLAN } from "./general";

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

const TOPIC_SUGGESTION = 0;
const SOURCE_SUGGESTION = 1;
//const AUTHOR_SUGGESTION = 2;

class AccountActionAttempt {
    constructor(wasSuccessful, message, isNewAccount) {
        this.wasSuccessful = wasSuccessful;
        this.message = message;
        this.isNewAccount = isNewAccount;
    }
}

class AccountCreationAttempt extends AccountActionAttempt {}
class EmailAddressConfirmationAttempt extends AccountActionAttempt {}
class SignInAttempt extends AccountActionAttempt {}

export class RemoteDataManager extends ZNDataManager {
    async attemptSignIn(emailAddress, password, keepExistingQueries) {
        const queryDataStr = keepExistingQueries ? JSON.stringify(this.zn.getAllQueryData()) : null;
        const signInAttemptData = await this.getSignInAttemptData(emailAddress, password, queryDataStr);
        const signInWasSuccessful = signInAttemptData.success;
        const responseMessage = signInAttemptData.message;
        const isNewAccount = signInAttemptData.isNewAccount;

        if(signInWasSuccessful) {
            this.processRemoteResponseData(signInAttemptData);
        }

        return new SignInAttempt(signInWasSuccessful, responseMessage, isNewAccount);
    }
    
    async getRemoteResponseToHttpRequest(requestFunc, endpointModifier, requestData) {
        return await requestFunc({
            url: ENDPOINT + '/' + endpointModifier,
            dataType: 'json',
            crossDomain: true,
            xhrFields: { withCredentials: true },
            data: requestData
        }).promise();
    }
    
    async getRemoteResponseToGetData(endpointModifier, requestData=undefined) {
        return await this.getRemoteResponseToHttpRequest($.get, endpointModifier, requestData);
    }

    async getRemoteResponseToPostData(endpointModifier, requestData=undefined) {
        return await this.getRemoteResponseToHttpRequest($.post, endpointModifier, requestData);
    }

    processRemoteResponseData(remoteData) {
        this.zn.processConceptData(remoteData.concepts, remoteData.conceptsIndexes);

        this.userId = remoteData.userId;
        this.userEmailAddress = remoteData.email;
        this.userPlan = PLAN_ID_TO_PLAN[remoteData.type];
        this.userSubscriptionNeedsAction = remoteData.subscriptionNeedsAction;
        this.userFollowQueries = this.zn.processQueriesDataAndGetQueriesSet(remoteData.followQueries);
        this.userHideQueries = this.zn.processQueriesDataAndGetQueriesSet(remoteData.blockQueries);

        if(remoteData.articleData) {
            this.userFeedContentItems = ContentItem.arrayFromDataArray(this.zn, remoteData.articleData);
        } else {
            this.userFeedContentItems = null;
        }
    }
    
    async read() {
        const remoteData = await this.getRemoteResponseToGetData('checkin');
        const requestWasSuccessful = remoteData.success;

        if(requestWasSuccessful) {
            this.processRemoteResponseData(remoteData);
        } else {
            this.setFieldsToDefaults();
        }
    }

    async enactFollowHide(action, query) {
        const updatedRemoteData = await this.recordQueryActionAndGetAccountAndFeedData(query, action);
        this.processRemoteResponseData(updatedRemoteData);
    }

    async followQuery(query) {
        super.followQuery(query);
        await this.enactFollowHide('follow', query);
    }

    async unfollowQuery(query) {
        super.unfollowQuery(query);
        await this.enactFollowHide('unfollow', query);
    }

    async hideQuery(query) {
        super.hideQuery(query);
        await this.enactFollowHide('block', query);
    }

    async unhideQuery(query) {
        super.unhideQuery(query);
        await this.enactFollowHide('unblock', query);
    }

    getSuggestedQueryFromSuggestionData(suggestionData) {
        const concept = this.zn.concepts[suggestionData[0]];
        const suggestionType = suggestionData[1];

        if(suggestionType === SOURCE_SUGGESTION) {
            return this.zn.getQueryFromConcepts(concept, [], []);
        } else if(suggestionType === TOPIC_SUGGESTION) {
            return this.zn.getQueryFromConcepts(null, [concept], []);
        } else /*if(suggestionType === AUTHOR_SUGGESTION)*/ {
            return this.zn.getQueryFromConcepts(null, [], [concept]);
        }
    }
    
    getSuggestedQueriesFromSuggestionsData(suggestionsData) {
        const responseSuggestions = [];

        for(const suggestionData of suggestionsData) {
            responseSuggestions.push(this.getSuggestedQueryFromSuggestionData(suggestionData));
        }

        return responseSuggestions;
    } 
    
    async getSearchSuggestionsFromQueryAndText(query, text) {
        const requestData = {
            text: text
        };

        if(query) {
            requestData.query = JSON.stringify(query.toData());
        }
        
        const responseData = await this.getRemoteResponseToGetData('suggest', requestData);
        const conceptsLabels = responseData.concepts;
        const conceptsIndexes = responseData.conceptsIndexes ?? {};
        const suggestionsData = responseData.suggestions;
        
        this.zn.processConceptData(conceptsLabels, conceptsIndexes);

        return this.getSuggestedQueriesFromSuggestionsData(suggestionsData);
    }

    async getAccountAndFeedData() {
        return await this.getRemoteResponseToGetData('checkin');
    }

    async getContentDataFromQueries(followQueries, hideQueries=new Set()) {
        return await this.getRemoteResponseToGetData('feed', {
            data: buildRequestJsonFromQueries(followQueries, hideQueries)
        });
    }

    async getContentItemsFromQueries(showQueries, hideQueries=new Set()) {
        if(showQueries.size > 0) {
            const responseData = await this.getContentDataFromQueries(showQueries, hideQueries);
            const contentItemsData = responseData.articleData;
            const conceptsLabels = responseData.concepts;
            const conceptsIndexes = responseData.conceptsIndexes ?? {};

            this.zn.processConceptData(conceptsLabels, conceptsIndexes);

            return ContentItem.arrayFromDataArray(this.zn, contentItemsData);
        } else {
            return [];
        }
    }

    async recordQueryActionAndGetAccountAndFeedData(query, action) {
        return await this.getRemoteResponseToGetData('updatequeries', {
            updates: JSON.stringify([{
                action: action,
                query: query.toData()
            }])
        });
    }

    async getCheckoutUrlFromStripePriceId(priceId) {
        const checkoutUrlDataObject = await this.getRemoteResponseToGetData('checkout', {
            priceId: priceId
        });
        const checkoutUrl = checkoutUrlDataObject.checkoutUrl;

        return checkoutUrl;
    }

    async getBillingPortalUrl() {
        const portalUrlDataObject = await this.getRemoteResponseToGetData('billingportal');
        const portalUrl = portalUrlDataObject.portalUrl;

        return portalUrl;
    }

    async signOut() {
        this.reset();
        return await this.getRemoteResponseToGetData('signout');
    }

    getRequestPathFromConfirmationEmailReason(reason) {
        if(reason === CONFIRM_SIGN_IN) {
            return 'startsigninemailauth';
        } else if(reason === CONFIRM_SIGN_UP) {
            return 'startsignupemailauth';
        } else {
            return 'startchangepasswordemailauth';
        }
    }

    async getSignInAttemptData(emailAddress, password, queryData) {
        const postData = {
            email: emailAddress,
            queryData: queryData
        }
        
        if(password) {
            postData.password = password;
        }

        return await this.getRemoteResponseToPostData('signin', postData);
    }

    async attemptPasswordChange(oldPassword, newPassword) {
        return await this.getRemoteResponseToPostData('changepassword', {
            oldPassword: oldPassword,
            newPassword: newPassword
        });
    }

    async requestConfirmationEmail(emailAddress, reason) {
        const requestPath = this.getRequestPathFromConfirmationEmailReason(reason);
        const requestData = reason !== CONFIRM_PASSWORD_CHANGE ? { email: emailAddress } : undefined;
        const responseData = await this.getRemoteResponseToPostData(requestPath, requestData);
        const confirmationWasSent = responseData.success;
        const message = responseData.error;

        return new EmailAddressConfirmationAttempt(confirmationWasSent, message);
    }

    async attemptEmailConfirmation(confirmationCode, emailAddress, setPassword, password, queryData) {
        const requestData = {
            code: confirmationCode
        };

        if(emailAddress) {
            requestData.email = emailAddress;
        }

        if(setPassword) {
            requestData.password = JSON.stringify(password);
        }

        if(queryData) {
            requestData.queryData = queryData;
        }

        const responseData = await this.getRemoteResponseToPostData('verifyemail', requestData);
        const creationWasSuccessful = responseData.success;
        const message = responseData.error;
        const isNewAccount = responseData.isNewAccount;

        if(creationWasSuccessful) {
            this.processRemoteResponseData(responseData);
        }

        return new AccountCreationAttempt(creationWasSuccessful, message, isNewAccount);
    }

    async handleDataUpdate(functionName, arg) {}
}