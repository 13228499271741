import React from 'react';
import $ from 'jquery';

import './App.css';

import Alert from './Alert';
import MainHeader from './MainHeader.js';
import Sidebar from './Sidebar.js';
import MainContent from './MainContent.js';
import { CHRONOLOGICAL_FEED_MODE, isFullscreen } from './general';
import { ZNCore } from './ZNCore';
import { trackNav, trackPageLoad, trackStartupComplete } from './CFAnalytics';

class AlertInfo {
    constructor(title, message, actionButtonText, actionButtonFunc) {
        this.title = title;
        this.message = message;
        this.actionButtonText = actionButtonText;
        this.actionButtonFunc = actionButtonFunc;
    }
}

class FeedDisplay {
    constructor(feed, query, isSearch, showAsFlat) {
        this.feed = feed;
        this.query = query;
        this.isSearch = isSearch;
        this.showAsFlat = showAsFlat;
    }
}

class App extends React.Component {
    constructor(props) {
        super(props);

        this.showLoading = this.showLoading.bind(this);
		this.handleSignIn = this.handleSignIn.bind(this);
        this.setFeedMode = this.setFeedMode.bind(this);
        this.loadAccountSettings = this.loadAccountSettings.bind(this);
        this.loadCreateAccount = this.loadCreateAccount.bind(this);
        this.noLogShowFilteredFeedFromQueryData = this.noLogShowFilteredFeedFromQueryData.bind(this);
        this.noLogShowSearchFeedFromQueryData = this.noLogShowSearchFeedFromQueryData.bind(this);
        this.hideWelcomeBanner = this.hideWelcomeBanner.bind(this);
        this.showPlans = this.showPlans.bind(this);
        this.showAboutPage = this.showAboutPage.bind(this);
        this.showContactPage = this.showContactPage.bind(this);
        this.showInstructionsPage = this.showInstructionsPage.bind(this);
        this.showPrivacyPolicyPage = this.showPrivacyPolicyPage.bind(this);
        this.showTermsPage = this.showTermsPage.bind(this);
        this.showUserFeed = this.showUserFeed.bind(this);
		this.openSidebar = this.openSidebar.bind(this);
		this.closeSidebar = this.closeSidebar.bind(this);
		this.closeSidebarOnSmallScreen = this.closeSidebarOnSmallScreen.bind(this);
		this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleHistoryPopstate = this.handleHistoryPopstate.bind(this);
        this.handleNavigationEvent = this.handleNavigationEvent.bind(this);
		this.handleSearchEvent = this.handleSearchEvent.bind(this);
        this.handleSearchFocus = this.handleSearchFocus.bind(this);
		this.handleSidebarAction = this.handleSidebarAction.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.runRefresh = this.runRefresh.bind(this);
        this.showUpgradeRequiredAlert = this.showUpgradeRequiredAlert.bind(this);
        this.showDisplayStateFromHistory = this.showDisplayStateFromHistory.bind(this);
        this.startCheckout = this.startCheckout.bind(this);
        this.visitBillingPortal = this.visitBillingPortal.bind(this);
        this.updateWhetherFullscreen = this.updateWhetherFullscreen.bind(this);

        this.zn = new ZNCore(this);

        this.state = Object.assign({
            display: 'loading',
            alert: null,
            isFullscreen: isFullscreen()
        }, this.getStateUpdateFromZNCore());

        this.upgradeRequiredAlert = new AlertInfo('Time for an upgrade?',
            "You've filled up your feed! Upgrade your account to follow or hide more searches.",
            'View plans', this.showPlans);
        
        this.subscriptionNeedsActionAlert = new AlertInfo('Your subscription needs attention',
            'There was an issue processing your subscription payment. Please visit the billing portal resolve this issue.',
            'Visit billing portal', this.visitBillingPortal);

        this.outsideCallableFuncs = [
            this.noLogShowFilteredFeedFromQueryData,
            this.noLogShowSearchFeedFromQueryData,
            this.showDisplayStateFromHistory
        ];

        this.outsideCallableFuncs.forEach((func, funcId) => {
            func.outsideCallableId = funcId;
        });
	}

    componentDidMount() {
		this.handleStartup();
        window.addEventListener('popstate', this.handleHistoryPopstate);
        window.addEventListener('resize', this.updateWhetherFullscreen);
	}

    componentWillUnmount() {
        window.removeEventListener('popstate', this.handleHistoryPopstate);
        window.removeEventListener('resize', this.updateWhetherFullscreen);
    }

    getStateUpdateFromZNCore() {
        const zn = this.zn;
        const userEmailAddress = zn.getUserEmailAddress();

        const accountData = userEmailAddress ? { email: userEmailAddress } : null;
        
        return {
            accountData: accountData,
            accountType: zn.getUserPlan(),
            followQueries: zn.getUserFollowQueries(),
            blockQueries: zn.getUserHideQueries(),
            feedMode: zn.getUserFeedMode(),
            subscriptionNeedsAction: zn.getWhetherUserSubscriptionNeedsAction()
        };
    }

    async handleStartup() {
        trackPageLoad();
        const zn = this.zn;
        await zn.startup();
        trackStartupComplete(zn);
        const stateUpdate = {
            accountType: zn.getUserPlan()
        };
        const urlStateData = this.getUrlStateData();

        if(urlStateData) {
            this.showFuncCallFromOutside(urlStateData);
            this.replaceCurrentHistoryStateWithOutsideState(urlStateData);
        } else {
            const isFirstVisit = zn.startedWithDefaults();
            const startingDisplayState = 'userFeed';
            stateUpdate.display = startingDisplayState;
            stateUpdate.shouldShowWelcomeBanner = isFirstVisit;

            this.replaceCurrentHistoryStateWithFuncCall(this.showDisplayStateFromHistory, [startingDisplayState]);
        }

        this.setState(stateUpdate);
    }

    hideWelcomeBanner() {
        this.setState({
            shouldShowWelcomeBanner: false
        });
    }

    showLoading() {
        this.setState({
            display: "loading"
        });
    }

    showReadableFeedNotFound() {
        this.setState({
            display: "readableFeedNotFound"
        });
    }

    handleCoreUpdate() {
        if(this.zn) {
            this.setState(this.getStateUpdateFromZNCore());
        }
    }
    
    async handleSignIn() {
        this.hideWelcomeBanner();
        this.showUserFeed();
	}

    handleQueryActionResult(wasSuccessful) {
        if(!wasSuccessful) {
            if(this.zn.getWhetherUserSubscriptionNeedsAction()) {
                this.showSubscriptionNeedsActionAlert();
            } else {
                this.showUpgradeRequiredAlert();
            }
        }
    }
    
    async followQuery(query) {
        this.handleQueryActionResult(await this.zn.followQuery(query));
    }

    async unfollowQuery(query) {
        await this.zn.unfollowQuery(query);
    }

    async hideQuery(query) {
        this.handleQueryActionResult(await this.zn.hideQuery(query));
    }

    async unhideQuery(query) {
        await this.zn.unhideQuery(query);
    }

    setLoggedDisplayState(displayStateName) {
        this.setState({
            display: displayStateName
        });
        trackNav(displayStateName);
        this.addDisplayStateToHistory(displayStateName);
    }

    setFeedMode(feedMode) {
        this.setState({
            feedMode: feedMode
        }, () => {
            this.showUserFeed();
            this.zn.setUserFeedMode(feedMode);
        });
    }
    
    showUserFeed() {
        this.setLoggedDisplayState('userFeed');
    }

    async showFeedFromQueries(displayQuery, showQueries, hideQueries=new Set(), showAsFromSearch=true) {
        this.setState({
            display: 'loading'
        });

        const feed = await this.zn.getFlatFeedFromQueries(showQueries, hideQueries);
        const feedDisplay = new FeedDisplay(feed, displayQuery, showAsFromSearch, true);

        this.setState({
            display: 'tempFeed',
            tempFeedDisplay: feedDisplay
        });
    }

    async showFeedFromQuery(query, hideQueries=new Set(), showAsFromSearch=true) {
        await this.showFeedFromQueries(query, new Set([query]), hideQueries, showAsFromSearch);
    }

    async noLogShowSearchFeedFromQuery(query) {
        await this.showFeedFromQuery(query, this.zn.getUserHideQueries(), true);
    }

    async noLogShowFilteredFeedFromQuery(query) {
        const combinedQueries = this.zn.intersectQueryWithQuerySet(query, this.zn.getUserFollowQueries());
        await this.showFeedFromQueries(query, combinedQueries, this.zn.getUserHideQueries(), false);
    }

    async noLogShowSearchFeedFromQueryData(queryData) {
        const query = this.zn.processQueryDataAndGetQuery(queryData);
        await this.noLogShowSearchFeedFromQuery(query);
    }

    async noLogShowFilteredFeedFromQueryData(queryData) {
        const query = this.zn.processQueryDataAndGetQuery(queryData);
        await this.noLogShowFilteredFeedFromQuery(query);
    }

    async showSearchFeedFromQuery(query) {
        await this.noLogShowSearchFeedFromQuery(query);
        this.addFuncCallToHistory(this.noLogShowSearchFeedFromQueryData, [query.toData()]);
    }

    async showFilteredUserFeedFromQuery(query) {
        await this.noLogShowFilteredFeedFromQuery(query);
        this.addFuncCallToHistory(this.noLogShowFilteredFeedFromQueryData, [query.toData()]);
    }

    loadAccountSettings() {
        this.setLoggedDisplayState('accountSettings');
    }

    loadCreateAccount() {
        this.setLoggedDisplayState('createAccount');
    }

    showPlans() {
        this.setLoggedDisplayState('plans');
    }

    showAboutPage() {
        this.setLoggedDisplayState('about');
    }

    showContactPage() {
        this.setLoggedDisplayState('contact');
    }

    showInstructionsPage() {
        this.setLoggedDisplayState('help');
    }

    showPrivacyPolicyPage() {
        this.setLoggedDisplayState('privacyPolicy');
    }

    showTermsPage() {
        this.setLoggedDisplayState('terms');
    }
	
	openSidebar() {
		this.setState({
			sidebarOpen: true
		});
        window.document.getElementById('sidebar').scrollTo(0, 0);
		$(':root').toggleClass('root-sidebar-open', true);
	}

	closeSidebar() {
		this.setState({
			sidebarOpen: false
		});

		$(':root').toggleClass('root-sidebar-open', false);
	}

	closeSidebarOnSmallScreen() {
		if(window.innerWidth <= 900) {
			this.closeSidebar();
		}
	}

    toggleSidebar() {
        if(this.state.sidebarOpen) {
			this.closeSidebar();
		} else {
			this.openSidebar();
		}
	}

    handleNavigationEvent() {
        window.scrollTo(0, 0);
    }

	handleSearchEvent() {
		this.closeSidebarOnSmallScreen();

        this.showLoading();

		this.handleNavigationEvent();
	}

    handleSearchFocus() {
        this.closeSidebarOnSmallScreen();
    }
	
	handleSidebarAction() {
		this.closeSidebarOnSmallScreen();
        this.handleNavigationEvent();
	}

    hideAlert() {
        this.setState({
            alertInfo: null
        });
    }

    async runRefresh() {
        this.showLoading();
        await this.zn.refreshUserFeed();
        this.showUserFeed();
    }

    showAlert(alertInfo) {
        this.setState({
            alertInfo: alertInfo
        });
    }

    showUpgradeRequiredAlert() {
        this.showAlert(this.upgradeRequiredAlert);
    }

    showSubscriptionNeedsActionAlert() {
        this.showAlert(this.subscriptionNeedsActionAlert);
    }

    async startCheckout(priceId) {
        const checkoutUrl = await this.zn.getCheckoutUrlFromStripePriceId(priceId);
        window.location.replace(checkoutUrl);
    }

    async visitBillingPortal() {
        const portalUrl = await this.zn.getBillingPortalUrl();
        window.location.replace(portalUrl);
    }

    async handleHistoryPopstate(event) {
        await this.showFuncCallFromOutside(event.state);
    }

    addDisplayStateToHistory(displayStateName) {
        this.addFuncCallToHistory(this.showDisplayStateFromHistory, [displayStateName]);
    }
    
    addFuncCallToHistory(func, args) {
        this.modifyHistoryFromHistoryMethodNameAndFuncCall('pushState', func, args);
    }

    replaceCurrentHistoryStateWithFuncCall(func, args) {
        this.modifyHistoryFromHistoryMethodNameAndFuncCall('replaceState', func, args);
    }

    replaceCurrentHistoryStateWithOutsideState(outsideState) {
        const funcAndArgs = this.getFuncAndArgsFromOutsideState(outsideState);

        if(funcAndArgs) {
            const [func, args] = funcAndArgs;
            this.replaceCurrentHistoryStateWithFuncCall(func, args);
        }
    }

    modifyHistoryFromHistoryMethodNameAndFuncCall(historyMethodName, func, args) {
        const outsideState = [func.outsideCallableId, ...args];
        const outsideStateJSON = JSON.stringify(outsideState);
        const isUserFeedRequest = func === this.showDisplayStateFromHistory && args.length === 1 && args[0] === 'userFeed';
        const url = isUserFeedRequest ? '/' : '?s=' + encodeURIComponent(outsideStateJSON);
        window.history[historyMethodName](outsideState, '', url);
    }

    showDisplayStateFromHistory(displayStateName) {
        this.setState({
            display: displayStateName
        });
    }

    navigateBack() {
        window.history.back();
    }

    async showFuncCallFromOutside(outsideState) {
        const funcAndArgs = this.getFuncAndArgsFromOutsideState(outsideState);
        
        if(funcAndArgs) {
            const [func, args] = funcAndArgs;
            await func(...args);
        }
    }

    getFuncAndArgsFromOutsideState(outsideState) {
        if(Array.isArray(outsideState)) {
            const funcId = outsideState[0];
            const func = this.outsideCallableFuncs[funcId];
            const args = outsideState.slice(1);
            return [func, args];
        } else {
            return null;
        }
    }

    getUrlStateData() {
        const encodedData = new URLSearchParams(document.location.search).get('s');

        if(encodedData) {
            const stateData = JSON.parse(decodeURIComponent(encodedData));
            return stateData;
        } else {
            return null;
        }
    }

    updateWhetherFullscreen() {
        this.setState({
            isFullscreen: isFullscreen()
        });
    }

    render() {
        let feedDisplay;
        
        if(this.state.display === 'userFeed') {
            const userFeed = this.zn.getUserFeed()
            const showAsFlat = this.state.feedMode === CHRONOLOGICAL_FEED_MODE;
            feedDisplay = new FeedDisplay(userFeed, null, false, showAsFlat);
        } else {
            const searchFeed = this.state.tempFeedDisplay;
            feedDisplay = searchFeed;
        }
        
        return (
            <div className="signed-in-view">
                <Alert alertInfo={ this.state.alertInfo } handleHideAlert={ this.hideAlert } showPlans={ this.showPlans }/>
                <MainHeader ui={ this } onSignInChange={ this.props.onSignInChange } email={ this.state.email }
                    showBackButton={ this.state.isFullscreen } onSearchData={ this.handleSearchData } showRemoteFeed={ this.showRemoteFeed }
                    toggleSidebar={ this.toggleSidebar }/>
                <div className="main">
                    <div className="header-space"/>
                    <div className="middle">
                        <Sidebar ui={ this } loadFeed={ this.loadSpecificFeed } showQueryTypeLabels={ this.state.display === 'plans' }
                            followQueries={ this.state.followQueries } blockQueries={ this.state.blockQueries }
                            loadAccountSettings={ this.loadAccountSettings } loadCreateAccount={ this.loadCreateAccount } showPlans={ this.showPlans }
                            showInstructionsPage={ this.showInstructionsPage } handleSignOut={ this.props.handleSignOut } toggleSidebar={ this.toggleSidebar } open={ this.state.sidebarOpen }
                            accountData={ this.state.accountData } accountType={ this.state.accountType }
                            showPrivacyPolicyPage={ this.showPrivacyPolicyPage } showTermsPage={ this.showTermsPage }/>
                        <div className="middle-grid">
                            <div className="sidebar-space"/>
                            <MainContent ui={ this } display={ this.state.display } feedDisplay={ feedDisplay }
                                shouldShowWelcomeBanner={ this.state.shouldShowWelcomeBanner }
                                followQueries={ this.state.followQueries } blockQueries={ this.state.blockQueries }
                                accountData={ this.state.accountData } accountType={ this.state.accountType } onSignIn={ this.handleSignIn }
                                handleNavigationEvent={ this.handleNavigationEvent } runRefresh={ this.runRefresh } startCheckout={ this.startCheckout }
                                visitBillingPortal={ this.visitBillingPortal } subscriptionNeedsAction={ this.state.subscriptionNeedsAction }/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
