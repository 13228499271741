import React from 'react';

function ArtemisLink(props) {
    return (
        <a href="https://blogs.nasa.gov/artemis/" target="_blank" rel="noopener noreferrer">
            { props.title ?? 'https://blogs.nasa.gov/artemis/' }
        </a>
    );
}

function ArtemisSource() {
    return <DisplaySource label="Artemis"/>;
}

function DisplaySource(props) {
    return <span className="tag source-tag">{ props.label }</span>;
}

function DisplayTopic(props) {
    return <span className="tag topic-tag">{ props.label }</span>;
}

function FollowButtonDown() {
    return <span className="fake-button info-bar-button info-bar-button-down">Unfollow</span>;
}

function FollowButtonUp() {
    return <span className="fake-button info-bar-button info-bar-button-up">Follow</span>;
}

/* function HideButtonDown() {
    return <span className="fake-button info-bar-button info-bar-button-down">Unhide</span>;
} */

function HideButtonUp() {
    return <span className="fake-button info-bar-button info-bar-button-up">Hide</span>;
}

function HubbleTopic() {
    return <DisplayTopic label="the Hubble Space Telescope"/>;
}

function MyFeed() {
    return <span className="text-blue text-bold">Your Crossfeed</span>;
}

function NasaTopic() {
    return <DisplayTopic label="NASA"/>;
}

function InverseSource() {
    return <DisplaySource label="Inverse"/>;
}

function TutorialTitle({title, description}) {
    return (
        <React.Fragment>
            <p className="info-title text-bold info-centered info-no-margin-below tutorial-title">{ title }</p>
            <p className="info-text text-subwhite info-centered help-entry">{ description }</p>
        </React.Fragment>
    );
}

class HelpPage extends React.Component {
    render() {
        return (
            <div className="info-page">
                <div className="info-section">
                    <div className="help-title text-bold">
                        Quick Start Guide
                    </div>
                    <ul className="info-text text-subwhite">
                        <li>
                            <p className="help-entry">
                                Search for and select topics, authors, and/or a source. Resulting content will match ALL specified criteria. For example,
                                to see content about <NasaTopic/> from <InverseSource/>:
                            </p>
                            <ul className="info-text text-subwhite">
                                <li>
                                    <p className="help-subentry">
                                        Type "<span className="text-italics text-white">NASA</span>" and select&nbsp;
                                        "<span className="text-white">about </span><NasaTopic/>".
                                    </p>
                                </li>
                                <li>
                                    <p className="help-subentry">
                                        Type "<span className="text-italics text-white">Inverse</span>" and select&nbsp;
                                        "<span className="text-white">from </span><InverseSource/>".
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className="help-entry">
                                Press <FollowButtonUp/> to include resulting content in <MyFeed/> or <HideButtonUp/> to exclude it.
                            </p>
                        </li>
                        <li>
                            <p className="help-entry">
                                Your browser stores your settings. Create an account to sync settings across devices.
                            </p>
                        </li>
                    </ul>
                    <TutorialTitle title="Tutorial #1"
                        description={ <>Follow news about <NasaTopic/> from <InverseSource/></> }
                    />
                    <p className="info-text text-subwhite help-entry">
                        To see content about <NasaTopic/>, type "<span className="text-italics text-white">NASA</span>" in the search 
                        bar above. From the dropdown that appears, select "<span className="text-white">about </span><NasaTopic/>". 
                        Notice that <NasaTopic/> is displayed in green. Crossfeed shows topics in this color 
                        to allow for quick visual identification.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        You should now see content about <NasaTopic/> from several different sources. Crossfeed supports 
                        many genres of content, but topic searches primarily return media from news outlets.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        When a tracked news outlet publishes new content, it will usually appear on Crossfeed within 30 minutes, though
                        it may take longer in some cases. Note that custom sources, which will be covered in Tutorial #2, 
                        show new content much sooner.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        For best results when searching, treat topics as keywords. For example, content about <NasaTopic/> is 
                        not guaranteed to include content about <HubbleTopic/>, despite <HubbleTopic/> being operated 
                        by <NasaTopic/>. To see news about both topics, search for and follow each one separately.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        You can filter your search results by adding another component to your search. 
                        To display <NasaTopic/> content exclusively from <InverseSource/>, 
                        type "<span className="text-italics text-white">Inverse</span>" into the empty part of the search bar and select 
                        "<span className="text-white">from </span><InverseSource/>". Notice that <InverseSource/> is displayed in purple. 
                        Crossfeed shows sources in this color.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        Notice that each piece of returned content is both about <NasaTopic/> and from <InverseSource/>.
                        Crossfeed returns content that matches all criteria specified in your search. For example, a search for content 
                        about <NasaTopic/> & <HubbleTopic/> will only return content that covers both topics. Content about one but not 
                        the other will be excluded.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        To follow <InverseSource/> content about <NasaTopic/>, press <FollowButtonUp/> below the search bar. Current and 
                        future content returned by this search will now be collected into <MyFeed/>, which you can access from the top of 
                        the sidebar.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        When you press <FollowButtonUp/>, notice that it changes to <FollowButtonDown/>. If you choose to  
                        unfollow <InverseSource/> content about <NasaTopic/>, press <FollowButtonDown/>, and it will be removed 
                        from <MyFeed/>.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        Near <FollowButtonDown/>, you should see <HideButtonUp/>. After you search, pressing <HideButtonUp/> tells 
                        Crossfeed to exclude content returned by this search both from <MyFeed/> and from your future searches. For example, 
                        following content from <InverseSource/> but hiding content about <NasaTopic/> tells Crossfeed to include 
                        all <InverseSource/> content in <MyFeed/> except for pieces about <NasaTopic/>.
                    </p>
                    <TutorialTitle title="Tutorial #2"
                        description={<>Follow content from a custom source, <ArtemisLink title="NASA's Artemis blog"/></>}
                    />
                    <p className="info-text text-subwhite help-entry">
                        Though Crossfeed provides access to a variety of news outlets through search, millions of other websites provide 
                        content that cannot be accessed this way. You can add outside content to <MyFeed/> by creating custom sources.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        To see content from <ArtemisSource/>, NASA's blog about the Artemis Program, copy & paste the blog's URL (<ArtemisLink/>) 
                        into the search bar. From the dropdown that appears, press "<span className="text-white">Select custom source</span>". 
                        After <ArtemisSource/> content loads, you can add it to <MyFeed/> by pressing <FollowButtonUp/>. Note that unlike content 
                        followed through a regular search, custom source content updates each time you load Crossfeed or refresh <MyFeed/>.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        In some cases, Crossfeed will not understand the web page at the URL you provide. If this happens, 
                        look for an RSS feed or Atom feed instead. These special lists of content are meant to be understood by software 
                        like Crossfeed.
                    </p>
                    <p className="info-text text-subwhite help-entry">For example, notice that at <ArtemisLink title="the Artemis blog"/>, there 
                        is a link titled <span className="text-italics">RSS</span> under the <span className="text-italics">SUBSCRIBE</span> heading 
                        (the location of this depends on your device). If you copy & paste the address of that link into the Crossfeed search 
                        bar and press "<span className="text-white">Select custom source</span>", Crossfeed will again load content 
                        from <ArtemisSource/>.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        Custom sources let you follow a broad range of content. In addition to blogs, some other things to try include:
                    </p>
                    <ul className="info-text text-subwhite help-entry">
                        <li>a podcast</li>
                        <li>a YouTube channel</li>
                        <li>a Reddit community</li>
                        <li>anything else with an RSS or Atom feed</li>
                    </ul>
                    <TutorialTitle title="Tutorial #3" description={<>Interact with <MyFeed/></>}/>
                    <p className="info-text text-subwhite help-entry">
                        Once you have followed content that interests you, you can view it by pressing <MyFeed/> at the top of the sidebar.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        If you followed the previous tutorials, you should see two rows of content: one about <NasaTopic/> and from <InverseSource/>, 
                        and one from <ArtemisSource/>.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        The contents of each row displayed here are sorted chronologically, with the most recent content appearing first. 
                        To see older content, scroll to the right. The rows themselves are also sorted chronologically by their newest content, 
                        with the most recently updated row appearing first.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        Most users will find this organizational structure easiest to quickly comprehend. However, you can switch between 
                        this grouped chronological view and an alternate, ungrouped chronological view by 
                        pressing <span className="help-icons-font">grid_view</span>.
                    </p>
                    <p className="info-text text-subwhite help-entry">
                        To filter <MyFeed/> by a specific search that you follow, select that search from the sidebar.
                    </p>
                </div>
            </div>
        )
    }
}

export default HelpPage;