import { getUsageData } from "./general";

export function Usage({ui}) {
    const zn = ui.zn;
    const followQueries = zn.getUserFollowQueries();
    const hideQueries = zn.getUserHideQueries();
    const plan = zn.getUserPlan();
    const [numFollowedSourceQueries, numFollowedPremiumQueries, numHiddenQueries] = getUsageData(followQueries, hideQueries);
    return (
        <span>
            Following { numFollowedSourceQueries } / { plan.sourceFollowCount } source searches
            <br/>
            Following { numFollowedPremiumQueries } / { plan.premiumFollowCount } premium searches
            <br/>
            Hiding { numHiddenQueries } / { plan.hideCount } searches
        </span>
    );
}