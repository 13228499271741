import React from 'react';

import Form from './Form.js';
import { trackSignInSuccess } from './CFAnalytics.js';

export class VerifyEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ''
        };

        this.handleVerifySubmit = this.handleVerifySubmit.bind(this);
    }

    async handleVerifySubmit(formData) {
        const enteredCode = formData.code;

        if(enteredCode.length === 0) {
            this.setState({
                message: 'Enter the verification code.'
            });
        } else {
            this.setState({
                message: this.workingMessage
            });

            const creationAttempt = await this.submitCode(enteredCode);

            if(creationAttempt.wasSuccessful) {
                this.handleSuccess(creationAttempt.isNewAccount);
            } else {
                const creationErrorMessage = creationAttempt.message;

                this.setState({
                    message: creationErrorMessage
                });
            }
        }
    }

    render() {
        return (
            <div className="info-section">
                <div className="info-title">
                    An email has been sent from <span className="text-bold text-italics">verify@zatemy.com</span> to <span className="text-bold text-italics">{ this.props.email }</span> containing a verification code. Enter it below within one hour 
                    to { this.messageAction }:
                </div>
                <Form submitText="Verify and create account" onSubmit={ this.handleVerifySubmit } message={ this.state.message }
                    fields={ [
                        {
                            label: 'Verification code',
                            id: 'code',
                            type: 'text',
                            autoComplete: 'off'
                        }
                    ] }/>
            </div>
        );
    }
}

export class VerifyEmailAndHandleSignIn extends VerifyEmail {
    handleSuccess(isNewAccount) {
        this.props.ui.handleSignIn();
        trackSignInSuccess(this.props.ui.zn.getUserId(), this.isSignIn, isNewAccount);
    }
}

export class VerifyEmailAndCallHandler extends VerifyEmail {
    handleSuccess() {
        this.props.onVerificationSuccess();
    }
}