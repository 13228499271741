import React from 'react';
import ReactTimeAgo from 'react-time-ago';

import InfinitelyScrollable from './InfinitelyScrollable';
import { AuthorsLabel, SourceLabel, MiddleDotsQueryLabel } from './Labels';
import { trackArticleOpen, trackFeedFilter } from './CFAnalytics';

const VERTICAL_INITIAL_VISIBLE_ARTICLE_COUNT = 10;
const HORIZONTAL_INITIAL_VISIBLE_ARTICLE_COUNT = 4;
const ARTICLE_ADD_COUNT = 2;

class Article extends React.Component {
    constructor(props) {
        super(props);
        this.handleArticleClick = this.handleArticleClick.bind(this);
        this.handleAuthorClick = this.handleAuthorClick.bind(this);
        this.handleSourceClick = this.handleSourceClick.bind(this);
    }

    handleArticleClick() {
        trackArticleOpen();
    }

    handleAuthorClick(query) {
        this.props.conceptClickAction(query);
        trackFeedFilter('article author', query);
    }

    handleSourceClick(query) {
        this.props.conceptClickAction(query);
        trackFeedFilter('article source', query);
    }
    
    render() {
        const article = this.props.article;

        let authorElements;

        if(article.authors.size > 0) {
            authorElements = (
                <AuthorsLabel ui={ this.props.ui } authors={ article.authors } componentClickAction={ this.handleAuthorClick }
                    key="authors"
                />
            );
        }
        
        return (
            <div className="grid-item">
                <a className="grid-item-img-div" href={ article.url } target="_blank" rel="noreferrer" onClick={ this.handleArticleClick }>
                    { article.imageUrl ? <img className="grid-img" src={ article.imageUrl } alt={ article.title }/> : null }
                    <div className="grid-img-overlay">
                        <div className="grid-img-fade"/>
                        <div className="grid-img-title clickable">
                            { article.title }
                        </div>
                    </div>
                </a>
                <div className="grid-item-text-div">
                    <div className="grid-item-details-div">
                        <div className="grid-item-authors-div">
                            { authorElements }
                        </div>
                        <div className="grid-item-subinfo">
                            <span className="grid-item-src-div">
                                <SourceLabel ui={ this.props.ui } source={ article.source } clickAction={ this.handleSourceClick }/>
                            </span>
                            <span className="grid-item-time-div">
                                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                                <ReactTimeAgo date={ article.time * 1000 }/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Subfeed extends React.Component {
    constructor(props) {
        super(props);

        this.handleLabelClick = this.handleLabelClick.bind(this);
    }
    
    handleLabelClick() {
        const query = this.props.query;
        this.props.ui.showFilteredUserFeedFromQuery(query);
        this.props.handleNavigationEvent();
        trackFeedFilter('subfeed label', query);
    }
    
    render() {
        return (
            <div className="subfeed">
                <div className="subfeed-label" onClick={ this.handleLabelClick }>
                    <MiddleDotsQueryLabel query={ this.props.query }/>
                </div>
                <div className="subfeed-content">
                    <ArticleFeed ui={ this.props.ui } zn={ this.props.zn } entries={ this.props.entries } conceptClickAction={ this.props.conceptClickAction }
                        horizontal={ true }
                    />
                </div>
            </div>
        );
    }
}

class ArticleFeed extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            status: "loading",
            allEntries: [],
            visibleEntries: []
        };
        
        this.showMoreEntries = this.showMoreEntries.bind(this);
    }

    componentDidMount() {
        this.displayEntries(this.props.entries);
    }

    componentDidUpdate(prevProps) {
        if(this.props.entries !== prevProps.entries) {
            this.displayEntries(this.props.entries);
        }
    }

    displayEntries(entries) {
        const initialArticleCount = this.props.horizontal ? HORIZONTAL_INITIAL_VISIBLE_ARTICLE_COUNT : VERTICAL_INITIAL_VISIBLE_ARTICLE_COUNT;
        const numArticlesToDisplay = Math.min(initialArticleCount, entries.length);
        const visibleEntries = [];
        
        for(let i=0; i<numArticlesToDisplay; i+=ARTICLE_ADD_COUNT) {
            this.showMoreEntriesFromAllEntriesAndVisibleEntries(entries, visibleEntries);
        }
        
        this.setState({
            status: "done",
            allEntries: entries,
            visibleEntries: visibleEntries
        });
    }

    showMoreEntriesFromAllEntriesAndVisibleEntries(allEntries, visibleEntries) {
        const numArticlesToAdd = Math.min(ARTICLE_ADD_COUNT, allEntries.length-visibleEntries.length);
        
        for(let i=0; i<numArticlesToAdd; i++) {
            let entry = allEntries[visibleEntries.length];
            let entryComponent;
            
            if(entry.items) {
                entryComponent = <Subfeed ui={ this.props.ui } zn={ this.props.zn } entries={ entry.items } query={ entry.query } handleNavigationEvent={ this.props.handleNavigationEvent }
                    loadFilteredUserFeed={ this.props.loadFilteredUserFeed } conceptClickAction={ this.props.conceptClickAction } key={ entry.query.key() }
                />;
            } else {
                entryComponent = <Article ui={ this.props.ui } zn={ this.props.zn } article={ entry }
                    conceptClickAction={ this.props.conceptClickAction } key={ entry.url }
                />;
            }

            visibleEntries.push(entryComponent);
        }
    }
    
    showMoreEntries() {
        let visibleEntries = [...this.state.visibleEntries];
        
        this.showMoreEntriesFromAllEntriesAndVisibleEntries(this.state.allEntries, visibleEntries);

        this.setState({
            visibleEntries: visibleEntries
        });
    }
    
    render() {
        let display;
        
        if(this.state.status === "loading") {
            display = (
                <div>Loading...</div>
            );
        } else if(this.state.status === "done") {
            if(this.state.allEntries.length > 0) {
                let gridClassName, endMargin;

                if(this.props.horizontal) {
                    gridClassName = 'grid horizontal-grid';
                    endMargin = '400px';
                } else {
                    gridClassName = 'grid vertical-grid';
                    endMargin = '600px';
                }

                display = (
                    <InfinitelyScrollable className={ gridClassName } items={ this.state.visibleEntries } endApproachingCallback={ this.showMoreEntries }
                        moreItemsAvailable={ this.state.visibleEntries.length < this.state.allEntries.length } horizontal={ this.props.horizontal } endMargin={ endMargin }
                    />
                );
            } else {
                display = (
                    <div className="no-articles">
                        No relevant content to show.
                    </div>
                );
            }
        }

        return display;
    }
}

export default ArticleFeed;