import React from 'react';

import { conceptLabelComparator } from './general';

class ConceptLabel extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }
    
    handleClick() {
        const clickAction = this.props.clickAction;
        
        if(clickAction) {
            const query = this.props.ui.zn.getQueryFromConceptAndTypeText(this.props[this.conceptTypeText], this.conceptTypeText);
            this.props.clickAction(query);
        }
    }
    
    render() {
        let spanClassName = "tag " + this.conceptTypeText + "-tag";

        if(this.props.clickAction) {
            spanClassName += " clickable";
        }
        
        return (
            <span className={ spanClassName } onClick={ this.handleClick }>
                { this.props[this.conceptTypeText].label }
            </span>
        )
    }
}

export class TopicLabel extends ConceptLabel {
    conceptTypeText = 'topic';
}

export class SourceLabel extends ConceptLabel {
    conceptTypeText = 'source';
}

export class AuthorLabel extends ConceptLabel {
    conceptTypeText = 'author';
}

class ConceptsLabel extends React.Component {
    render() {
        // The label should be of the form "concept, concept, concept, & concept".
        const labelParts = [];

        const concepts = [...this.props[this.conceptTypeText + 's']].sort(conceptLabelComparator);  // Sort the concepts by their labels.
        const conceptsCount = concepts.length;

        let conceptsLeft = conceptsCount;
        
        for(let i=0; i<conceptsCount; i++) {
            const concept = concepts[i];

            const labelProps = {
                ui: this.props.ui,
                clickAction: this.props.componentClickAction,
                key: concept.id
            };
            labelProps[this.conceptTypeText] = concept;
            
            labelParts.push(
                <this.LabelType {...labelProps}/>
            );

            conceptsLeft -= 1;

            // If 1 concept is left, add "&".
            if(conceptsLeft === 1) {
                // If there are only 2 concepts total, put just the "&".
                if(conceptsCount === 2) {
                    labelParts.push(
                        <span className="tag-punct" key={ "sep" + i.toString() }>
                            &nbsp;&&nbsp;
                        </span>
                    );
                // If there are more than 2 concepts, put ", &".
                } else {
                    labelParts.push(
                        <span className="tag-punct" key={ "sep" + i.toString() }>
                            , &&nbsp;
                        </span>
                    );
                }
            // If more than 1 concepts is left, add ",".
            } else if(conceptsLeft >= 2) {
                labelParts.push(
                    <span className="tag-punct" key={ "sep" + i.toString() }>
                        ,&nbsp;
                    </span>
                );
            }
        }
        
        return (
            <span>
                { labelParts }
            </span>
        )
    }
}

export class TopicsLabel extends ConceptsLabel {
    conceptTypeText = 'topic';
    LabelType = TopicLabel;
}

export class AuthorsLabel extends ConceptsLabel {
    conceptTypeText = 'author';
    LabelType = AuthorLabel;
}

function interleaveComponents(components, insideComponentTemplate) {
    const interleavedComponents = [];
    const insideComponentsCount = components.length - 1;
    let i;

    for(i=0; i<insideComponentsCount; i++) {
        const insideComponent = React.cloneElement(insideComponentTemplate);
        interleavedComponents.push(
            <span className="query-label-part" key={ 'inner' + i.toString() }>
                { components[i] }
                <React.Fragment>
                    { insideComponent }
                </React.Fragment>
            </span>
        );
    }

    interleavedComponents.push(components[i]);
    return interleavedComponents;
}

export class PhraseQueryLabel extends React.Component {
    render() {
        const components = [];
        
        const query = this.props.query;
        const contentPrefix = this.props.contentPrefix;

        if(query) {
            const source = query.source;
            const topics = query.topics;
            const authors = query.authors;

            let sourceLabel, topicsLabel, authorsLabel;

            if(source) {
                sourceLabel = <SourceLabel source={ source } key="sourceLabel"/>;
            }

            if(topics.length > 0) {
                topicsLabel = <TopicsLabel topics={ topics } key="topicsLabel"/>;
            }

            if(authors.length > 0) {
                authorsLabel = <AuthorsLabel authors={ authors } key="authorsLabel"/>;
            }

            if(topicsLabel || authorsLabel) {
                if(sourceLabel) {
                    components.push(sourceLabel);
                }

                if(contentPrefix) {
                    components.push(
                        <span key="contentPrefix">
                            content
                        </span>
                    );
                }

                if(authorsLabel) {
                    components.push(
                        <span key="preAuthorsText">
                            by
                        </span>,
                        authorsLabel
                    );
                }

                if(topicsLabel) {
                    components.push(
                        <span key="preTopicsText">
                            about
                        </span>,
                        topicsLabel
                    );
                }
            } else if(sourceLabel) {
                if(contentPrefix) {
                    components.push(
                        <span key="contentPrefix">
                            content
                        </span>
                    );
                }
                
                components.push(
                    <span key="preSourceFrom">
                        from
                    </span>,
                    sourceLabel
                );
            }
        } else if(contentPrefix) {
            components.push(
                <span key="contentPrefix">
                    all content
                </span>
            );
        }

        if(this.props.fromFeed) {
            components.push(
                <span key="innerText1">
                    from
                </span>,
                <span className="tag feed-tag" key="feedTag">
                    Your Crossfeed
                </span>
            );
        } else if(this.props.fromSearch) {
            components.push(
                <span key="innerText1">
                    from search
                </span>
            );
        }

        const spacedComponents = interleaveComponents(
            components,
            <React.Fragment>
                &nbsp;
            </React.Fragment>
        );

        return (
            <div className="query-label">
                { spacedComponents }
            </div>
        );
    }
}

class SeparatorQueryLabel extends React.Component {
    render() {
        const query = this.props.query;
        const source = query.source;
        const topics = query.topics;
        const authors = query.authors;
        const components = [];

        if(topics.length > 0) {
            components.push(
                <React.Fragment key="topics">
                    about&nbsp;
                    <TopicsLabel topics={ topics } key="topicsLabel"/>
                </React.Fragment>
            );
        }

        if(authors.length > 0) {
            components.push(
                <React.Fragment key="authors">
                    by&nbsp;
                    <AuthorsLabel authors={ authors }/>
                </React.Fragment>
            );
        }

        if(source) {
            components.push(
                <React.Fragment key="source">
                    from&nbsp;
                    <SourceLabel source={ source }/>
                </React.Fragment>
            );
        }

        const commaSeparatedComponents = interleaveComponents(
            components,
            this.separator
        );

        return (
            <div className="query-label">
                { commaSeparatedComponents }
            </div>
        );
    }
}

export class CommasQueryLabel extends SeparatorQueryLabel {
    separator = (
        <React.Fragment>
            ,&nbsp;
        </React.Fragment>
    );
}

export class MiddleDotsQueryLabel extends SeparatorQueryLabel {
    separator = (
        <React.Fragment>
            &nbsp;&nbsp;&middot;&nbsp;&nbsp;
        </React.Fragment>
    );
}