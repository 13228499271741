import { FREE_USER, GROUPED_FEED_MODE } from "./general";

export class DataManager {}

export class DataManagerWithUserInfo extends DataManager {
    constructor() {
        super();
        
        this.setFieldsToDefaults();
    }
    
    isQueryActionName(functionName) {
        return ['followQuery', 'unfollowQuery', 'hideQuery', 'unhideQuery'].includes(functionName);
    }
    
    setFieldsToDefaults() {
        this.userId = null;
        this.userEmailAddress = null;
        this.userPlan = FREE_USER;
        this.userSubscriptionNeedsAction = false;
        this.userFeedMode = GROUPED_FEED_MODE;
        this.userFollowQueries = new Set();
        this.userHideQueries = new Set();
        this.userFeedContentItems = [];
        this.userIsSignedIn = false;
    }

    updateAttributesFromDataManager(dataManager) {
        this.userId = dataManager.getUserId();
        this.userEmailAddress = dataManager.getUserEmailAddress();
        this.userPlan = dataManager.getUserPlan();
        this.userSubscriptionNeedsAction = dataManager.getWhetherUserSubscriptionNeedsAction();
        this.userFollowQueries = dataManager.getUserFollowQueries();
        this.userHideQueries = dataManager.getUserHideQueries();
        this.userFeedMode = dataManager.getUserFeedMode();
        this.userFeedContentItems = dataManager.getUserFeedContentItems();
        this.userIsSignedIn = this.userEmailAddress !== null;
    }

    async followQuery(query) {
        this.userFollowQueries.add(query);
        this.userHideQueries.delete(query);

        await this.handleDataUpdate('followQuery', query);
    }

    async unfollowQuery(query) {
        this.userFollowQueries.delete(query);

        await this.handleDataUpdate('unfollowQuery', query);
    }

    async hideQuery(query) {
        this.userHideQueries.add(query);
        this.userFollowQueries.delete(query);

        await this.handleDataUpdate('hideQuery', query);
    }

    async unhideQuery(query) {
        this.userHideQueries.delete(query);

        await this.handleDataUpdate('unhideQuery', query);
    }

    async setUserFeedMode(feedMode) {
        this.userFeedMode = feedMode;

        await this.handleDataUpdate('setUserFeedMode', feedMode);
    }

    getUserId() {
        return this.userId;
    }

    getUserEmailAddress() {
        return this.userEmailAddress;
    }

    getUserPlan() {
        return this.userPlan;
    }

    getWhetherUserSubscriptionNeedsAction() {
        return this.userSubscriptionNeedsAction;
    }

    getUserFeedMode() {
        return this.userFeedMode;
    }

    getUserFollowQueries() {
        return new Set(this.userFollowQueries);
    }

    getUserHideQueries() {
        return new Set(this.userHideQueries);
    }

    getUserFeedContentItems() {
        return this.userFeedContentItems;
    }

    async handleDataUpdate(functionName, arg) {}

    reset() {
        this.setFieldsToDefaults();
    }

    setUserFollowQueries(followQueries) {
        this.userFollowQueries = new Set(followQueries);
    }

    setUserHideQueries(hideQueries) {
        this.userHideQueries = new Set(hideQueries);
    }
}

export class ZNDataManager extends DataManagerWithUserInfo {
    constructor(zn, updateNotifier) {
        super();

        this.zn = zn;
        this.updateNotifier = updateNotifier;
    }
}