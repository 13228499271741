import ZatemyLogo from "./ZatemyLogo";

export function AboutPage({ui}) {
    function handleLearnClick() {
        ui.showInstructionsPage();
        ui.handleNavigationEvent();
    }

    return (
        <div className="info-page">
            <div className="info-section">
                <div className="help-title text-bold">
                    About <ZatemyLogo/>
                </div>
                <p className="info-text help-entry">
                    If you read or watch a lot of internet content, you’ve probably noticed that:
                </p>
                <ul className="info-text text-subwhite">
                    <li><p className="help-entry">You sift through lots of irrelevant headlines and videos.</p></li>
                    <li><p className="help-entry">Despite your intentions, pointless clickbait sometimes lures you in.</p></li>
                    <li><p className="help-entry">Your favorite content is scattered around the web in places you forget to check regularly.</p></li>
                </ul>
                <p className="info-text help-entry">
                    As a result, your time is wasted and you miss out on great content. To solve these problems, Crossfeed lets you:
                </p>
                <ul className="info-text text-subwhite">
                    <li><p className="help-entry">Follow news topics, journalists, blogs, RSS feeds, YouTube channels, and more in a single feed so you don’t miss great content.</p></li>
                    <li><p className="help-entry">Hide content by topics, sources, and more so you stay focused and spend less time scrolling.</p></li>
                </ul>
                <p className="info-text help-entry">
                    Ready to get started? <span className="fake-link" onClick={handleLearnClick}>Learn how to use Crossfeed</span>.
                </p>
            </div>
        </div>
    );
}