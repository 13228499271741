import mixpanel from 'mixpanel-browser';
import { textToBool } from './general';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: textToBool(process.env.REACT_APP_MIXPANEL_ENABLE_DEBUG) });

function getQueryTrackingDataObj(query) {
    return {
        hasSource: query.hasASource(),
        hasCustomSource: query.hasCustomSource(),
        numAuthors: query.authors.length,
        numTopics: query.topics.length
    };
}

export function trackArticleOpen() {
    mixpanel.track('article open', {}, { send_immediately: true });
}

export function trackFeedFilter(uiElement, query) {
    mixpanel.track(
        'feed filter',
        {
            uiElement: uiElement,
            ...getQueryTrackingDataObj(query)
        }
    );
}

export function trackMyFeedChange(actionName, query) {
    mixpanel.track(
        'My Feed change',
        {
            action: actionName,
            ...getQueryTrackingDataObj(query)
        }
    );
}

export function trackManagePlanSelect(currentPlan, callback) {
    mixpanel.track(
        'manage plan',
        {
            currentPlanName: currentPlan.name
        },
        {
            send_immediately: true
        },
        callback
    );
}

export function trackNav(pageName) {
    mixpanel.track(
        'navigate',
        {
            page: pageName
        }
    );
}

export function trackPageLoad() {
    const pageRef = new URLSearchParams(document.location.search).get('ref');

    if(pageRef === 'cfcheckoutsuccess') {
        mixpanel.track(
            'checkout finished',
            {
                success: true
            }
        );
    } else if(pageRef === 'cfcheckoutcancel') {
        mixpanel.track(
            'checkout finished',
            {
                success: false
            }
        );
    } else if(pageRef === 'cfmanagereturn') {
        mixpanel.track('manage plan complete');
    } else {
        mixpanel.track(
            'page load',
            {
                urlRef: pageRef
            }
        );
    }
}

export function trackPlanSelect(plan, callback) {
    mixpanel.track(
        'plan select',
        {
            planName: plan.name
        },
        {
            send_immediately: true
        },
        callback
    );
}

export function trackSearch(query) {
    mixpanel.track(
        'search',
        {
            ...getQueryTrackingDataObj(query)
        }
    );
}

export function trackSignInAttempt(fromSignInPage, usingEmailAuth) {
    mixpanel.track(
        'sign in attempt',
        {
            origin: fromSignInPage ? 'sign in' : 'create account',
            method: usingEmailAuth ? 'email' : 'password'
        }
    );
}

export function trackSignInFailure(fromSignInPage, cause) {
    mixpanel.track(
        'sign in failure',
        {
            origin: fromSignInPage ? 'sign in' : 'create account',
            cause: cause
        }
    );
}

export function trackSignInSuccess(userId, fromSignInPage, isNewAccount) {
    mixpanel.track(
        isNewAccount ? 'create account' : 'sign in',
        {
            origin: fromSignInPage ? 'sign in' : 'create account'
        }
    );

    if(isNewAccount) {
        mixpanel.alias(userId);
    } else {
        mixpanel.identify(userId);
    }
}

export function trackSignOut() {
    mixpanel.track('sign out');
    mixpanel.reset();
}

export function trackStartupComplete(zn) {
    const userId = zn.getUserId();

    if(userId !== null && userId !== undefined) {
        mixpanel.identify(userId);
    }
}