import React from 'react';

class ZatemyLogo extends React.Component {
    render() {
        return (
            <span className="zatemy-logo" onClick={ this.props.onClick }>
                Crossfeed
            </span>
        );
    }
}

export default ZatemyLogo;