import React from 'react';
import { genAddr } from './general';

class PrivacyPolicyPage extends React.Component {
    render() {
        return (
            <div className="info-page">
                <div className="info-section">
                    <div className="info-title">
                        Privacy Policy
                    </div>
                    <div className="info-text">
                        <p>
                            Last updated January 11, 2023
                        </p>
                        <p>
                            This Privacy Policy is meant to help you understand how Zatemy LLC (“Zatemy”) collects, handles, uses, and shares your information in connection with your use of Zatemy’s products, websites, applications, and services (collectively the “Services”). Zatemy may make updates and changes to this Privacy Policy in the future. You can see these updates and changes by returning to this page.
                        </p>
                        <p className="info-subtitle">
                            Subprocessors
                        </p>
                        <p>
                            In order to provide the Services, Zatemy partners with third party subprocessors (the “Subprocessors”) that may use cookies or other on-device information storage and may process, collect, store, or share your information. The following is a list of the Subprocessors and links to their privacy policies at the time of writing:
                        </p>
                        <ul>
                            <li>
                                Amazon Web Services, Inc. (<a href="https://aws.amazon.com/privacy" target="_blank" rel="noopener noreferrer">privacy policy</a>) - Email delivery
                            </li>
                            <li>
                                Cloudflare, Inc. (<a href="https://www.cloudflare.com/privacypolicy" target="_blank" rel="noopener noreferrer">privacy policy</a>) - Content storage and provision
                            </li>
                            <li>
                                DigitalOcean, LLC (<a href="https://www.digitalocean.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a>) - Server hosting, content delivery network provision
                            </li>
                            <li>
                                Google LLC (<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">privacy policy</a>) - Email service provision and company information storage
                            </li>
                            <li>
                                Mixpanel, Inc. (<a href="https://mixpanel.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a>) - Crossfeed usage tracking and analytics
                            </li>
                            <li>
                                Stripe, Inc. (<a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">privacy policy</a>) - Payment processing
                            </li>
                        </ul>
                        <p className="info-subtitle">
                            Content sources
                        </p>
                        <p>
                            The Services sometimes provide you with links to content from third parties and their products, websites, applications, and services (collectively the “Sources”). Images or other media associated with linked content may be loaded from the Sources and displayed by the Services. For information about how the Sources collect, handle, use, and share your information, how they use cookies and other on-device information storage, how they respond to Do Not Track headers, and other privacy-related information, please consult their respective privacy policies.
                        </p>
                        <p className="info-subtitle">
                            Information collection and usage
                        </p>
                        <p>
                            The devices and software you use to access the Services may automatically provide your public IP address, details about your web browser and operating system, or other information to the Services and the Subprocessors when interacting with them. Zatemy may log this information.
                        </p>
                        <p>
                            If you choose to sign up for a Zatemy account, you will be asked to provide an email address to associate with your new account. This email address may be used to send you confirmations, invoices, account updates, marketing or promotional material, or other information.
                        </p>
                        <p>
                            Zatemy will never sell your information to third parties without your consent, except in connection with the sale or merger of Zatemy or one of the Services.
                        </p>
                        <p>
                            Zatemy will share your information with third parties when required under applicable law.
                        </p>
                        <p className="info-subtitle">
                            Cookies and local storage
                        </p>
                        <p>
                            Zatemy may sometimes utilize first-party cookies or local storage to improve your experience using the Services by preserving your settings and allowing you to create and access a Zatemy account.
                        </p>
                        <p>
                            Images or other media loaded from the Sources by the Services sometimes use cookies that are not controlled by Zatemy.
                        </p>
                        <p>
                            If you prefer that the Services or the Sources do not use cookies or local storage, please disable those features in your browser settings.
                        </p>
                        <p className="info-subtitle">
                            Do Not Track
                        </p>
                        <p>
                            The Services currently do not respond to Do Not Track headers that may be included in browser requests.
                        </p>
                        <p className="info-subtitle">
                            Questions and Account Deletion
                        </p>
                        <p>
                            If you have a question about this privacy policy or would like to request your Zatemy account be deleted, 
                            please send an email to { genAddr('ycavirp') }.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default PrivacyPolicyPage;